import { Box } from "@mui/material";
import { FC, ReactElement } from "react";
import { Hero } from "../components/Hero";
import HeroBg from "../assets/gifs/hero_test.gif"

export const Resources: FC<any> = (): ReactElement => {

    return(
        <Box>

            <Hero
                bgImage={HeroBg}
                title={"Resources"}
                subtitle={"Here are some things to help our fellow game devs"}
            />
           
        </Box>
    )
    
}