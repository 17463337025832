import { Box, } from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { companyInfo } from "../data/companyInfo";
import { GamesGallery } from "../components/portfolio/GamesGallery";

export const Portfolio: FC<any> = (): ReactElement => {

    const {selectedGameIndex}: any = useParams()

    const [defaultGame, setDefaultGame] = useState(selectedGameIndex)

    const navbar = document.getElementById("navbar")

    navbar?.scrollIntoView()

    // useEffect(() => {

    //     if(!selectedGameIndex) return

    //     setDefaultGame(selectedGameIndex)

    // }, [selectedGameIndex])

    return(
        <Box>

            <GamesGallery
                allGames={companyInfo.games}
                defaultSelectedGame={defaultGame}
            />

        </Box>
    )
    
}