import { Box } from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { companyInfo } from "../../data/companyInfo";
import { Service } from "../../data/types";
import { Hero } from "../Hero";

export const ServiceView: FC<any> = (): ReactElement => {

    const {serviceId}: any = useParams()

    const [service, setService] = useState<Service | undefined>()
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {

        setService(companyInfo.services[serviceId])

        setIsLoading(false)

    }, [serviceId])

    return(
        <Box>

            {
                !isLoading && service && (
                    <Hero
                        bgImage={service.image}
                        title={service.name}
                        // subtitle={service.subtitle}
                    />
                )
            }
        
            

        </Box>
    )

}