import { Box, Stack } from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import { Game } from "../../data/types";
import { CurvedLine } from "../dividers/CurvedLine";
import { CurvedSegment } from "../dividers/CurvedSegment";
import { Hero } from "../Hero";
import { GalleryDisplay } from "./GalleryDisplay";
import { GamesDisplay } from "./GamesDisplay";
import HeroBg from "../../assets/gifs/hero_test.gif"

export const GamesGallery: FC<any> = ({
    allGames,
    defaultSelectedGame,
}: {
    allGames: Game[],
    defaultSelectedGame: number
}):ReactElement => {

    console.log("rendering games gallery")

    console.log("default selected game is: ", defaultSelectedGame)

    const [gameIndex,setGameindex] = useState<number>(defaultSelectedGame)

    console.log("default game index: ", gameIndex)

    const [imageIndex, setImageIndex] = useState<number>(0)

    const allGamesLength = allGames.length

    let gameImagesLength: any = allGames[gameIndex].gallery.length

    useEffect(() => {

        console.log("in componenet loaded use effect ")

        setGameindex(defaultSelectedGame)

        console.log("default selected game is: ", defaultSelectedGame)
        console.log("default game index: ", gameIndex)

    }, [])

    useEffect(() => {

        console.log("in game index use effect")

        if (!gameIndex) return

        gameImagesLength = allGames[gameIndex].gallery.length

        setImageIndex(0)

    }, [gameIndex])

    const changeImage = (direction: string) => {

        console.log("current image index: ", imageIndex)
        console.log("game images length: ", gameImagesLength)
        console.log("current game index: ", gameIndex)
        console.log("all games length: ", allGamesLength)

        if ( direction === "next") {

            if (imageIndex === gameImagesLength - 1) {

                if(gameIndex === allGamesLength - 1) {

                    setGameindex(0)

                    setImageIndex(0)

                }
                else {

                    setGameindex(gameIndex + 1)

                    setImageIndex(0)

                }

            }
            else setImageIndex(imageIndex + 1)

        }
        else if ( direction === "prev" ) {

            if (imageIndex === 0) {

                if(gameIndex === 0) {

                    const newImageIndex = allGames[allGamesLength - 1].gallery.length - 1

                    setGameindex(allGamesLength - 1)

                    setImageIndex(newImageIndex)

                }
                else {

                    const newImageIndex = allGames[gameIndex - 1].gallery.length - 1

                    setGameindex(gameIndex - 1)

                    setImageIndex(newImageIndex)
                }
                
            }
            else setImageIndex(imageIndex - 1)

        }

    }

    const gotoImage = (index: number) => {

        setImageIndex(index)

    }

    const gotoGame = (index: number) => {

        console.log("going to game index: ", index)

        setGameindex(index)

        setImageIndex(0)
    }

    return(
        <>

            <Hero
                bgImage={HeroBg}
                title={allGames[gameIndex].name}
                subtitle={allGames[gameIndex].genre}
                description={allGames[gameIndex].description}
                children={
                    <GalleryDisplay
                        gallery={allGames[gameIndex].gallery}
                        imageIndex={imageIndex}
                        changeImage={changeImage}
                        gotoImage={gotoImage}
                    />
                }
            />

            <Stack
                justifyContent="center"
                alignItems="center"
                // maxWidth="700px"
                sx={{
                    // backgroundColor: "red"
                }}
            >

                <GamesDisplay
                    allGames={allGames}
                    gotoGame={gotoGame}
                    gameIndex={gameIndex}
                />
            
            </Stack>

            <CurvedSegment
                direction="up"
                color="dark"
                marTop="0px"
                marBottom="-6px"
                snapTo="top"
            />

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "3%",
                    backgroundColor: "primary.contrastText",
                }}
            >

                <CurvedLine
                    color="white"
                    direction="up"
                />

            </Box>
        
        </>
      
    )
}