export const socialData = [
    {
        name: "website",
        link: "https://www.sintiempo.dev/",
    },
    {
        name: "linkedin",
        link: "https://www.linkedin.com/company/sin-tiempo-llc/",
    },
    {
        name: "twitter",
        link: "https://twitter.com/SinTiempo_LLC",
    },
    {
        name: "newsletter",
        link: "https://sintiempo.substack.com/",
    },
    // {
    //     name: "upwork",
    //     link: "",
    //     icon: GroupWork,
    // },
    {
        name: "email",
        link: "admin@sintiempo.dev",
    }
]