import { Box } from "@mui/material";
import { FC, ReactElement } from "react";
import { Hero } from "../components/Hero";
import HeroBg from "../assets/gifs/hero_test.gif"

export const Services: FC<any> = (): ReactElement => {

    return(
        <Box>
            
            <Hero
                bgImage={HeroBg}
                title={"Services"}
                subtitle={"Get more details on what we can do for you"}
            />

        </Box>
    )
}