import { Alert, Button, Card } from "@mui/material"
import { FC, ReactElement, useState } from "react"
import { AlertObject } from "../../data/types"
import { EmailInput } from "./inputs/EmailInput"
import { MessageInput } from "./inputs/MessageInput"
import { NameInput } from "./inputs/NameInput"
import { SubjectInput } from "./inputs/SubjectInput"

var validator = require('validator')

const defaultContactFormData ={
    name: "EarthWorm Jim",
    email: "you@email.com",
    // phoneNumber: "5558675309",
    subject: "quote",
    message: "The future of hill valley is at great risk!",
    access_key: ""
}

const defaultInputFilledData = {
    name: false,
    email: false,
    // phoneNumber: false,
    subject: true,
    message: false,
}

export const ContactForm: FC<any> = ({contactInfo}): ReactElement => {

    const [contactFormData, setContactFormData] = useState<any>(defaultContactFormData)

    const [inputFilled, setInputFilled] = useState<any>(defaultInputFilledData)

    const [isSending, setIsSending] = useState<boolean>(false)

    const [formFilled, setFormFilled] = useState<boolean>(false)

    const [alert, setAlert] = useState<AlertObject | undefined>(undefined)

    const handleFormChange = (event: any, formLabel: string) => {

        let tempFormData = contactFormData

        tempFormData[formLabel] = event.target.value

        setContactFormData(tempFormData)

        let tempInputFilled = inputFilled

        if ( event.target.value === "" ) {

            tempInputFilled[formLabel] = false

        } else {

            if ( formLabel === "email" ) {

                if ( validator.isEmail(tempFormData[formLabel]) ) tempInputFilled[formLabel] = true
                
                else tempInputFilled[formLabel] = false

            }

            else if ( formLabel === "name" ) {

                if ( validator.isAlpha(tempFormData[formLabel], ['en-US'], { ignore: ' ' } ) && tempFormData[formLabel].trim().length > 2 ) tempInputFilled[formLabel] = true

                else tempInputFilled[formLabel] = false
                
            }

            else if ( formLabel === "message" ) {

                if ( tempFormData[formLabel].length > 19 ) tempInputFilled[formLabel] = true

                else tempInputFilled[formLabel] = false

            }

            else tempInputFilled[formLabel] = true

        }
        
        // console.log("input filled is now: ", tempInputFilled)

        setInputFilled(tempInputFilled)

        checkIfFormFilled()

    }

    const resetInputs = () => {

        for ( const [key, value] of Object.entries(defaultContactFormData) ) {

            const element = document.getElementById(`contact-${key}`)

            if( !element ) continue

            if ( key == "subject" ) {

                // @ts-ignore
                element.value = defaultContactFormData[key]

            }
            else {
                //@ts-ignore
                element.value = ""
            }

        }

    }

    const checkIfFormFilled = () => {

        for ( const [key, value] of Object.entries(inputFilled) ) {
            
            if ( inputFilled[key] === false) {
                
                setFormFilled(false)

                return

            }

        }

        setFormFilled(true)

    }

    const submitForm = (e: any) => {

        setIsSending(true)

        e.preventDefault()

        let formattedData = {
            ...contactFormData,
            subject: "SinTiempo.dev Request => " + contactFormData.subject,
            access_key: contactInfo.access_key
        }

        var json = JSON.stringify(formattedData)

        const fetchConfig = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: json
        }

        fetch("https://api.web3forms.com/submit", fetchConfig)
            .then(async(res: any) => {
                let json = await res.json()

                const message: string = json.message

                if ( res.status == 200 ) {

                    setAlert({
                        type: "success",
                        message: "Email Successfully Sent! We will Respond ASAP"
                    })

                } else {

                    setAlert({
                        type: "error",
                        message: message
                    })

                }
            }).catch((error: any) => {

                setAlert({
                    type: "error",
                    message: error
                })

            }).then(() => {

                setContactFormData(defaultContactFormData)

                setInputFilled(defaultInputFilledData)

                setIsSending(false)

            })

    }

    return(
        <Card
            sx={{
                width: "340px",
                padding: "20px"
            }}
        >

            <NameInput
                value={contactFormData.name}
                setValue={(e: any) => handleFormChange(e, "name")}
            />

            <EmailInput
                value={contactFormData.email}
                setValue={(e: any) => handleFormChange(e, "email")}
            />

            <SubjectInput
                value={contactFormData.subject}
                setValue={(e: any) => handleFormChange(e, "subject")}
            />

            <MessageInput
                value={contactFormData.message}
                setValue={(e: any) => handleFormChange(e, "message")}
            />
            
            {
                alert
                ? (
                    <Alert
                        // @ts-ignore
                        severity={alert.type}
                        // @ts-ignore
                        message={alert.message}
                        onClose={() => {

                            setAlert(undefined)

                            resetInputs()

                        }}
                        sx={{
                            marginTop: "10px"
                        }}
                    >
                        {alert.message}
                    </Alert>
                )
                : (
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "secondary.main",
                            color: "primary.light",
                            width: "300px",
                            marginTop: "10px",
                        }}
                        onClick={submitForm}
                        disabled={!formFilled}
                    >
                        {
                            isSending
                            ? "sending"
                            : "submit"
                        }
                    </Button>
                )
                
            }

        </Card>
    )

}