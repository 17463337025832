import { Box, Typography } from "@mui/material";
import Modal from "@mui/material/Modal"
import { FC, ReactElement, useEffect } from "react";

export const AssetModal: FC<any> = ({
    isOpen,
    setIsOpen,
    assetSrc
}: {
    isOpen: boolean,
    setIsOpen: Function,
    assetSrc: any
}):ReactElement => {

    return(
        <Box>

            <Modal
                open={isOpen}
                onClose={() => setIsOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box
                    sx={{
                        // borderRadius: "10px",
                        // overflow: "hidden",
                        height: "100vh",
                        width: "100vw",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // left: "0px",
                        // right: "0px",
                        // top: "0px",
                        // bottom: "0px",
                        // marginY: "auto",
                        // position: "absolute",
                        flexDirection: "column",
                    }}
                >

                    <Typography
                        color="primary.light"
                        onClick={() => setIsOpen(false)}
                        textAlign="center"
                        sx={{
                            transition: "all 1s",
                            cursor: "pointer",
                            borderRadius: "5px",
                            padding: "5px 20px",
                            backgroundColor: "rgba(255,255,255, 0.5)",
                            marginBottom: "10px",
                            ":hover": {
                                backgroundColor: "secondary.main"
                            }
                        }}
                    >
                        close
                    </Typography>

                    <video
                        src={assetSrc}
                        autoPlay
                        onEnded={() => setIsOpen(false)}
                        style={{
                            maxHeight: "80%",
                            maxWidth: "100%",
                            
                            // height: "100%",
                        }}
                        // @ts-ignore
                        volume={0.1}
                        controls
                        id="assetModalVideo"
                        onPlay={(e) => {

                            // @ts-ignore
                            e.target.volume = 0.2
                        }}
                    />
                </Box>


            </Modal>
        </Box>
    )
}