import { Avatar, Box, Container, Stack, Typography } from "@mui/material"
import { FC, ReactElement } from "react"
import { CurvedSegment } from "../dividers/CurvedSegment"
import bgImage from "../../assets/gifs/hero_test_0.gif"
import { MiniSocial } from "./MiniSocial"

export const MemberHero: FC<any> = ({member}): ReactElement => {
    return(
        <Box
            sx={{
                // width: "100%",
                position: "relative",
                height: "600px",
                zIndex: 0,
                backgroundImage: `url(${bgImage})`,
                backgroundAttatchment: "fixed",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom",
                backgroundSize: "cover",
                // paddingY: "3%",
            }}
        >

            <Container
                maxWidth="xl"
                sx={{
                    zIndex: 2,
                    position: "absolute",
                    display: "flex",
                    // flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "500px",
                    left: "0px",
                    right: "0px",
                    margin: "auto",
                    gap: "20px",
                    flexWrap: "wrap",
                    paddingY: "3%",
                }}
            >

                <Avatar
                    src={member.pfp}
                    alt={member.name}
                    sx={{
                        height: 300,
                        width: 300,
                        // border: "5px solid black",
                        // borderColor: "secondary.main",
                        boxShadow: "0 0 0 5px #FFBA39, 0 0 0 10px #FB601C, 0 0 0 15px #951B17"
                        
                    }}
                />

                <Stack>

                    <Typography
                        fontSize="3rem"
                        color="primary.light"
                        fontWeight="bold"
                        textAlign="center"
                        lineHeight="3rem"
                        sx={{
                            textShadow: "0px 2px 0px #FB601C"
                        }}
                    >
                        {`${member.name}`}
                    </Typography>

                    <Typography
                        fontSize="1.5rem"
                        color="secondary.light"
                        fontWeight="bold"
                        textAlign="center"
                        sx={{
                            textShadow: "0px 2px 0px #951B17",
                            letterSpacing: "1px",
                        }}
                    >
                        {`${member.title}`}
                    </Typography>

                    <Typography
                        sx={{
                            maxWidth: " 500px",
                            textAlign: "center",
                            color: "primary.light",
                            textShadow: "0px 0px 3px black",
                            backgroundColor: "rgba(3,3,3, 0.5)",
                            marginY: "10px",
                            borderRadius: "10px",
                            paddingY: "5px"
                        }}
                    >
                        {member.intro}
                    </Typography>

                    <MiniSocial member={member} />

                </Stack>

            </Container>

            {/* <Container
                maxWidth="xl"
                sx={{
                    zIndex: 2,
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "500px",
                    left: "0px",
                    right: "0px",
                    margin: "auto",
                    gap: "20px",
                }}
            >

                <Typography
                    fontSize="4rem"
                    color="primary.light"
                    fontWeight="bold"
                    textAlign="center"
                    lineHeight="4rem"
                    sx={{
                        textShadow: "0px 2px 0px #FB601C"
                    }}
                >
                    {`" ${companyInfo.missionStatement} "`}
                </Typography>

                <Typography
                    fontSize="2rem"
                    color="secondary.light"
                    fontWeight="bold"
                    textAlign="center"
                    sx={{
                        textShadow: "0px 2px 0px #951B17",
                        letterSpacing: "1px",
                    }}
                >
                    {`${companyInfo.missionStatementSubTitle}`}
                </Typography>

            </Container> */}

            <CurvedSegment
                direction="up"
                color="white"
                marTop="0px"
                marBottom="0px"
                zInd={2}
                snapTo="bottom"
            />

        </Box>
    )
}