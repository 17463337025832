import { FormControl, MenuItem, TextField } from "@mui/material"
import { FC, ReactElement } from "react"

export const SubjectInput: FC<any> = ({value, setValue}): ReactElement => {

    return(
        <FormControl>

            <TextField
                id="contact-subject"
                select
                label="subject"
                defaultValue="quote"
                onChange={setValue}
                sx={{
                  marginTop: "10px",  
                  marginBottom: "10px",
                  width: "300px",
                }}
            >

                <MenuItem value="quote">Quote</MenuItem>

                <MenuItem value="service">Service</MenuItem>

                <MenuItem value="general">General</MenuItem>

            </TextField>

        </FormControl>
    )

}