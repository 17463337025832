import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material"
import { Box, Stack, Typography } from "@mui/material"
import { FC, ReactElement } from "react"
import { Image } from "../../data/types"
import { AssetDisplay } from "./AssetDisplay"
import { ImagesDisplay } from "./ImagesDisplay"

export const GalleryDisplay: FC<any> = ({
    gallery,
    imageIndex,
    changeImage,
    gotoImage,
}: {
    gallery: Image[],
    imageIndex: number,
    changeImage: Function,
    gotoImage: Function,
}):ReactElement => {

    return(
        <Stack
            justifyContent="center"
            alignItems="center"
            gap="5px"
            marginTop="8px"
        >

            <Box
                sx={{
                    position: "relative",
                    height: "400px",
                    width: "800px",
                    borderRadius: "10px",
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >

                <AssetDisplay
                    assetData={gallery[imageIndex]}
                />

                <Typography
                    // fontWeight="bold"
                    sx={{
                        // backgroundColor: "rgba(3,3,3,0.8)",
                        backgroundColor: "primary.dark",
                        border: "2px solid orange",
                        // boxShadow: "0px 3px orange",
                        color: "primary.light",
                        position: "absolute",
                        bottom: "40px",
                        zIndex: 2,
                        paddingLeft: "5px",
                        textAlign: "center",
                        paddingY: "5px",
                        paddingX: "20px",
                        borderRadius: "5px",
                        opacity: 0.8,
                    }}
                >
                    {gallery[imageIndex].caption}
                </Typography>

                <Box
                    sx={{
                        transition: "all 1s",
                        cursor: "pointer",
                        position: "absolute",
                        right: "20px",
                        top: "0px",
                        bottom: "0px",
                        marginY: "auto",
                        opacity: 0.5,
                        backgroundColor: "secondary.main",
                        borderRadius: "5px",
                        zIndex: 2,
                        padding: "3px",
                        height: "30px",
                        width: "30px",
                        color: "primary.light",
                        ":hover": {
                            opacity: 1,
                        },
                    }}
                    onClick={() => {changeImage("next")}}
                >

                    <ArrowForwardIos color="inherit" />

                </Box>

                <Box
                    sx={{
                        
                        transition: "all 1s",
                        cursor: "pointer",
                        position: "absolute",
                        left: "20px",
                        top: "0px",
                        bottom: "0px",
                        marginY: "auto",
                        opacity: 0.5,
                        backgroundColor: "secondary.main",
                        borderRadius: "5px",
                        zIndex: 2,
                        paddingLeft: "6px",
                        paddingTop: "3px",
                        height: "30px",
                        width: "30px",
                        color: "primary.light",
                        ":hover": {
                            opacity: 1,
                        }
                    }}
                    onClick={() => {changeImage("prev")}}
                >

                    <ArrowBackIos
                        color="inherit"
                    />

                </Box>

            </Box>

            <ImagesDisplay
                images={gallery}
                gotoImage={gotoImage}
                imageIndex={imageIndex}
            />

        </Stack>

    )
    
}