import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    palette: {
        primary: {
            light: '#F5F5F5',
            main: '#5a6f8c',
            dark: '#000',
            contrastText: '#000',
        },
        secondary: {
            main: '#FB601C',
            light: '#FFBA39',
            dark: '#951B17',
            contrastText: '#fff ',
        },
    }
})