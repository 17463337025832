import { Box, Container, Grid, Typography } from "@mui/material"
import { FC, ReactElement } from "react"

export const Footer: FC = (): ReactElement => {
    
    return(
        <Box
            sx={{
                width: "100%",
                height: "auto",
                backgroundColor: "primary.dark",
                color: "primary.light",
            }}
        >

            <Container maxWidth="lg">

                <Grid container direction="column" alignItems="center">

                    <Grid item xs={12}>

                        <Typography
                            variant="subtitle2">
                            { `copyright©️ ${new Date().getFullYear()} | Sin Tiempo LLC`}
                        </Typography>

                    </Grid>

                </Grid>

            </Container>

        </Box>
    )
    
}