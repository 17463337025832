import { Box, Container, Stack, Typography } from "@mui/material"
import { FC, ReactElement } from "react"
import { companyInfo } from "../../data/companyInfo"
import { Shield, Groups, LocalFireDepartment  } from "@mui/icons-material"
import { Tenet } from "../../data/types"

const icons: any = {
    "Integrity": <Shield sx={{ fontSize: 50 }} />,
    "Comradery": <Groups sx={{ fontSize: 50 }} />,
    "Passion": <LocalFireDepartment sx={{ fontSize: 50 }} /> 
}

const TenetCard: FC<any> = ({tenetData}) => {
    const icon = icons[tenetData.title]

    return(
        <Stack
            direction="column"
            gap="10px"
            alignItems="center"
        >

            <Box
                sx={{
                    color: "secondary.main",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                }}
            >
                {icon}

            </Box>

            <Box>

                <Typography
                    textAlign="center"
                    fontWeight="bold"
                    fontSize="1.5rem"
                    color="secondary.main"
                >
                    {tenetData.title}
                </Typography>

                <Typography
                    fontSize=".8rem"
                    textAlign="center"
                    color="primary.main"
                    sx={{
                        maxWidth: "200px"
                    }}
                >
                    {tenetData.message}
                </Typography>

            </Box>

        </Stack>   
    )
}

export const HomeTenets: FC<any> = (): ReactElement => {

    const tenetCards = companyInfo.tenets.map((tenet: Tenet, index: number) => {

        return(
            <TenetCard key={index} tenetData={tenet} />
        )

    })

    return(
        <>

            <Container
                maxWidth="xl"
                sx={{
                    // paddingTop: "0px",
                    paddingBottom: "4%",
                }}
            >

                <Typography
                    textAlign="center"
                    color="primary.main"
                    sx={{
                        marginTop: "10px",
                        marginBottom: "20px"
                    }}
                    fontSize=".9rem"
                >
                    Games work because they have rules, so do we.
                </Typography>

                <Container
                    sx={{
                        display: "flex",
                        gap: "5px",
                        justifyContent: "center",
                    }}
                >
                    
                    { tenetCards }

                </Container>

            </Container>
            
        </>
    )
}