import { FC, ReactElement } from "react";
import { Image } from "../../data/types";
import vidTest from "../../assets/images/games/atlantis_world/TRAILER_OP_Quest.mp4"

const ImageDisplay = ({imageData}: {imageData: Image}) => {

    return (
        <img
            src={imageData.image}
            alt={imageData.caption}
            style={{
                width: "100%",
                height: "100%",
                // maxWidth: "700px",
                // height: "300px",
                position: "relative",
                zIndex: 1,
                objectFit: "contain",
                imageRendering: "pixelated",
                // borderRadius: "10px",
                // overflow: "hidden",
            }}
            loading="lazy"
        />
    )

}

const VideoDisplay = ({videoData}: {videoData: Image}) => {

    return(
        <video
            src={videoData.image}
            style={{
                width: "100%",
                height: "100%",
                position: "relative",
                zIndex: 1,
            }}
            autoPlay
            preload="auto"
            loop
        />
    )

}

export const AssetDisplay: FC<any> = ({assetData}: {assetData: Image}):ReactElement => {

    if ( assetData.type === "image" ) return <ImageDisplay imageData={assetData} />

    else if ( assetData.type === "video") return <VideoDisplay videoData={assetData} />

    return <></>

}