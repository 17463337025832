import { Box, Stack } from "@mui/material";
import { FC, ReactElement } from "react";
import { Image } from "../../data/types";

export const Thumb = ({
    image,
    alt,
    onclick,
    index,
    size,
    selectedIndex,
    thumb,
}: {
    image: string,
    alt: string,
    onclick: Function,
    index: number,
    size: string,
    selectedIndex: number,
    thumb: string | undefined
}) => {

    const heightSize =
        size === "sm"
        ? "50px"
        : size === "lg"
        ? "100px"
        : "200px"
    
    const widthSize =
        size === "sm"
        ? "50px"
        : size === "lg"
        ? "150px"
        : "200px"

    const borderCol =
        index === selectedIndex
        ? "secondary.main"
        : "rgba(3,3,3, 0)"

    return (
        <Box
            key={index}
            onClick={() => onclick(index)}
            sx={{
                cursor: "pointer",
                transition: "all 1s",
                borderRadius: "5px",
                overflow: "hidden",
                opacity: 0.8,
                height: heightSize,
                width: widthSize,
                border: "3px solid black",
                borderColor: borderCol,
                ":hover": {
                    opacity: 1,
                },
                backgroundColor: "primary.light",
                flexShrink: 0,
            }}
        >
            <img
                src={
                    thumb
                    ? thumb
                    : image
                }
                alt={alt}
                loading="lazy"
                style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover"
                }}
            />
        </Box>
    )
}

export const ImagesDisplay:FC<any> = ({
    images,
    gotoImage,
    imageIndex
}: {
    images: Image[],
    gotoImage: Function,
    imageIndex: number
}):ReactElement => {

    const allImagesDisplay = images.map((image: Image, index: number) => {
        return(
            <Box
                key={index}
            >

                <Thumb
                    image={image.image}
                    alt={image.caption}
                    onclick={gotoImage}
                    index={index}
                    size="sm"
                    selectedIndex={imageIndex}
                    thumb={image.thumb}
                />
            
            </Box>
        )
    })

    return(
        <Stack
            direction="row"
            gap="2px"
            maxWidth="800px"
            sx={{
                marginX: "auto",
                overflowX: "scroll",
                paddingBottom: "5px",
                // flexGrow: 1,
                // justifyContent: "flex-end",
                flexShrink: 0,
                '&::-webkit-scrollbar': {
                    width: '0.4em'
                    },
                    '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                    },
                    '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'secondary.main',
                    }
            }}
        >

            {allImagesDisplay}

        </Stack>
    )

}