import { Box, Card, Container, Stack, Typography, Link, ListItem, List, } from "@mui/material";
import { FC, ReactElement, useState } from "react";
import { NavLink } from "react-router-dom";
import { companyInfo } from "../../data/companyInfo";
import { Game } from "../../data/types";
import { CurvedLine } from "../dividers/CurvedLine";
import { CurvedSegment } from "../dividers/CurvedSegment";

const Services: FC<any> = ({services}):ReactElement => {

    const listItems = services.map((s:any, i:number) => {
        return(
            <Box
                sx={{
                    fontSize: 9,
                    color: "secondary.light",
                    backgroundColor: "primary.main",
                    borderRadius: "3px",
                    // width: "100%",
                    // margin: "5px",
                    // flex: 1,
                    width: "110px",
                    padding: "5px"
                }
                }
            >
                {s}
            </Box>
        )
    })
    return (
        <Box
            
            sx={{
                // width: "100%",
                padding: "0px",
                margin: "auto",
                display: "flex",
                gap: 1,
                flexWrap: "wrap",
                justifyContent: "center",
            }}
            
        >
            {listItems}
        </Box>
    )
}

const WorkCard: FC<any> = ({type, item}):ReactElement => {

    const [hovering, setHovering] = useState(false)

    const nameColor =
        hovering
        ? "primary.light"
        : "secondary.main"

    const descColor =
        hovering
        ? "primary.light"
        : "primary.dark"

    const { details } = item

    const client = type === "client"

    return(
        <Card
            sx={{
                transition: "all 1s",
                cursor: "pointer",
                width: client ? "600px" : "400px",
                backgroundColor: client ? "transparent" : "primary.light",
                // height: type === "client" ? "280px": "350px",
                ":hover": {
                    backgroundColor: !client ? "secondary.main" : "transparent",
                },
                borderRadius: 0
            }}
            onMouseEnter={() => {
                if(!client) setHovering(true)
            }
            }
            onMouseLeave={() => {setHovering(false)}}
        >

            <Stack
                direction={client ? "row" : "column"}
                sx={{
                    backgroundColor: "transparent",
                    gap: "0px"
                }}
            >

                <Box
                    sx={{
                        // backgroundColor: "primary.light",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: client ? "5px" : "10px",
                        backgroundColor: "white",
                        borderRadius: client ? "5px 0px 5px 5px" : "5px",
                    }}
                >

                    <img
                        src={item.image}
                        alt={`${item.name} logo`}
                        width={client ? "200px" : "400px"}
                        // height={client ? "250px" : "300px"}
                        style={{
                            position: "relative",
                            // padding: "10px",
                            height: client ? "200px" : "210px",  
                            objectFit: client ? "contain": "cover",
                            // border: client ? `3px solid ${nameColor}` : "none",
                            // borderColor: client ? nameColor : "none"
                        }}
                    />

                </Box>

                {/* <Box>

                    <CurvedLine
                        direction="up"
                        color="white"
                    />

                </Box>

                <Box>

                    <CurvedSegment
                        direction="up"
                        color="white"
                    />

                </Box> */}

                <Box
                    sx={{
                        // padding: "10px",
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        // justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        // backgroundColor: "blue"

                        // backgroundColor: "transparent",
                    }}
                >

                    <Typography
                        fontSize="1.3rem"
                        sx={{
                            fontWeight: "bold",
                            backgroundColor: client ? nameColor : "unset",
                            color: client ? "primary.light" : nameColor,
                            width: client ? "100%" : "unset",
                            borderTopRightRadius: "5px"
                        }}
                    >
                        {item.name}
                    </Typography>

                    {

                        type === "game"
                        ? (
                            <Typography color={descColor}>
                                {item.genre}
                            </Typography>
                        )
                        :
                        type === "client"
                        ? (
                            <Box
                                sx={{
                                    width: "100%",
                                }}
                            >
                                <Typography color={"primary.main"}
                                    sx={{
                                        backgroundColor: "primary.light",
                                        borderBottomRightRadius: "5px"
                                    }}
                                >
                                 {details.name}   
                                </Typography>
                                <Box
                                    sx={{
                                        margin: 2
                                    }}
                                >
                                    {details.tasks.map((d:any, i: number) => {
                                        return (
                                            <Typography color={"primary.main"} fontSize={12}justifyContent={"left"} textAlign={"left"} paddingLeft={1}>
                                                - {d}
                                            </Typography>
                                        )
                                    })}
                                </Box>
                                
                                
                                {/* <Typography color={"primary.main"} fontSize={12} padding={1}>
                                    {details.tasks}
                                </Typography> */}
                                <Box>
                                    <Services services={details.services} />
                                </Box>
                            </Box>
                        )
                        : <></>
                    
                    }

                </Box>
                
            </Stack>

        </Card>
    )

}

export const HomeClients: FC<any> = (): ReactElement => {

    const clients = companyInfo.work.map((client: any, index: number) => {

        return(
            <Box
                key={index}
            >

                <Link
                    key={client.name}
                    to={client.link}
                    component={NavLink}
                    underline="none"
                >

                    <WorkCard
                        type="client"
                        item={client}
                    />

                </Link>

            </Box>       
        )

    })

    const games = companyInfo.games.map((game: Game, index: number) => {

        if ( game.featured === true ) return(
            <Box
                key={index}
            >
                <Link
                    key={game.name}
                    to={`portfolio/${index}`}
                    component={NavLink}
                    underline="none"
                >

                    <WorkCard
                        type="game"
                        item={game}
                    />

                </Link>

            </Box>
        )
        else return <Box key={index} sx={{display: "none"}}></Box>

    })

    return(
        <>

            <Box
                sx={{
                    backgroundColor: "primary.contrastText",            
                }}
            >

                <Container
                    sx={{
                        paddingY: "5%",
                        display: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >

                    <Typography
                        color="primary.main"
                        textAlign="center"
                        sx={{
                            marginBottom: "20px"
                        }}
                    >
                        We are proud of the work we have done and the connections we have made to our actively growing network. 
                    </Typography>

                    <Typography
                        color="secondary.light"
                        textAlign="center"
                        marginBottom="10px"
                    >
                        clients
                    </Typography>

                    <Box
                        display="flex"
                        sx={{
                            flexWrap:"wrap",
                            marginX: "auto",
                            justifyContent: "center",
                            gap: "10px",
                        }}
                    >

                        {clients}

                    </Box>

                    <Typography
                        color="secondary.light"
                        textAlign="center"
                        marginBottom="10px"
                        marginTop="20px"
                    >
                        games
                    </Typography>

                    <Box
                        display="flex"
                        sx={{
                            flexWrap:"wrap",
                            marginX: "auto",
                            justifyContent: "center",
                            gap: "10px",
                        }}
                    >

                        {games}

                    </Box>

                </Container>

                <CurvedLine
                    color="white"
                    direction="down"
                />

            </Box>

            <CurvedSegment
                direction="down"
                color="dark"
                marTop="0px"
                marBottom="0px"
            />

        </>
        
    )

}