import { FC, ReactElement } from "react";
import CurvedWhiteLine from "../../assets/svg/curved_line-white.svg"
import CurvedDarkLine from "../../assets/svg/curved_line-dark.svg"
import CurvedWhiteLineLeft from "../../assets/svg/curved_line-white-left.svg"
import CurvedDarkLineLeft from "../../assets/svg/curved_line-dark-left.svg"

export const CurvedLine:FC<any> = ({
    color,
    direction
}): ReactElement => {

    const imgSxStyling: any = 
        direction === "up" || direction === "left"
        ?
        {}
        :
        {
            transform: "rotate(180deg)"
        }

    const imgSrc =
        color === "white"
        ? direction === "left" || direction === "right" 
        ? CurvedWhiteLineLeft
        : CurvedWhiteLine
        : direction === "left" || direction === "right"
        ? CurvedDarkLineLeft
        : CurvedDarkLine

    return(
        <img
            style={imgSxStyling}
            src={imgSrc}
            alt={`${color} curved line`}
        />
    )

}