import { Box, Container, IconButton, Link, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { FC, ReactElement, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { links } from "../routes";
import { NavLink } from "react-router-dom";
import SinTiempoLogo from "../assets/svg/sin_tiempo-logo-icon.svg"
import SinTiempoText from "../assets/svg/sin_tiempo-logo-text.svg"

export const Navbar: FC<any> = (): ReactElement => {

    const [anchorElNav, setAnchorElNav] = useState(null)

    const handleOpenNavMenu = (event: any) => {
        setAnchorElNav(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    return(
        <Box
            sx={{
                width: "100%",
                backgroundColor: "primary.dark",
                padding: "0px",
                boxShadow: 5,
                position: "relative",
                zIndex: 1,
            }}
        >
            <Toolbar
                disableGutters
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >

                <Box sx={{
                    display: { xs: "flex", md: "none" },
                    padding: "0px",
                    
                }}>

                    <Box 
                        sx={{
                            backgroundColor: "secondary.main",
                            height: "65px",
                            width: "65px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        
                        <IconButton
                            size="large"
                            aria-label="accoutn of  current user"
                            aria-controls="menu-appbar"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                            sx={{
                                borderRadius: "0px",
                                margin: "0px",
                                height: "100%",
                            }}
                        >

                            <MenuIcon />

                        </IconButton>

                    </Box>

                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: {xs: "block", md: "none"},
                        }}
                    >
                        {
                            links.map(
                                (page) => (
                                    <Link
                                        key={page.key}
                                        component={NavLink}
                                        to={page.path}
                                        color="primary.dark"
                                        underline="none"
                                        variant="button"
                                        sx={{
                                            ":hover": {
                                                color: "secondary.main"
                                            }
                                        }}
                                    >

                                        <MenuItem onClick={handleCloseNavMenu}>

                                            <Typography textAlign="center">{page.title}</Typography>

                                        </MenuItem>
                                        
                                    </Link>
                                )
                            )
                        }

                    </Menu>

                </Box>

                <Box
                    sx={{
                        display: { xs: "none", md: "block" },
                        // justifyContent: "center",
                        backgroundColor: "secondary.main",
                        height: "65px"
                    }}
                >

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginRight: "2rem",
                            height: "100%",
                        }}
                    >

                        {
                            links.map(
                                (page) => (
                                    <Link
                                        key={page.key}
                                        component={NavLink}
                                        to={page.path}
                                        color="primary.dark"
                                        underline="none"
                                        variant="button"
                                        sx={{
                                            marginLeft: "2rem",
                                            ":hover": {
                                                color: "primary.light"
                                            }
                                        }}
                                    >

                                        {page.title}

                                    </Link>
                                )
                            )
                        }

                    </Box>

                </Box>

                <Box
                    paddingY="5px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    sx={{
                        position: "absolute",
                        left: "0px",
                        right: "0px",
                        marginX: "auto",
                        width:"250px",
                    }}
                >

                    <img height="25px" src={SinTiempoText} alt="Sin Tiempo SVG text" />

                </Box>

                <Box padding="5px 10px" display="flex" flexDirection="column" justifyContent="center">

                    <img height="50px" src={SinTiempoLogo} alt="Sin Tiempo SVG logo" />

                </Box>
                
            </Toolbar>

        </Box>
    );
};