import { Container, Typography } from "@mui/material"
import { FC, ReactElement } from "react"

export const MemberBio: FC<any> = ({member}): ReactElement => {

    return(
        <Container
            sx={{
                color: "primary.main",
                width: "88%",
                paddingY: "3%"
            }}
        >

            {/* <Typography>
                {member.intro}
            </Typography> */}

            <Typography>
                {member.bio}
            </Typography>

        </Container>
    )

}