import MinimallyInvasive from "../../assets/images/games/minimally_invasive/minimally_invasive.png"
import AtlantisWorld from "../../assets/images/games/atlantis_world/atlantis_world.png"
import AdventureDogs from "../../assets/images/games/adventure_dogs/adventure_dogs.png"
import Beam from "../../assets/images/games/beam/beam.png"
import BlockMagic from "../../assets/images/games/block_magic/block_magic.png"
import Bushido from "../../assets/images/games/bushido/bushido.png"
import Recollect from "../../assets/images/games/recollect/recollect.png"
import SuperSkyHero from "../../assets/images/games/super_sky_hero/super_sky_hero_1.png"
import WrongFloor from "../../assets/images/games/wrong_floor/wrong_floor.png"
import TrickerTrigger from "../../assets/images/games/tricker_trigger/tricker_trigger_1.png"
import Lambings from "../../assets/images/games/lambings/sheep.png"
import Catastrophe from "../../assets/images/games/catastrophe/catastrophe.png"
import ForgetMeNot from "../../assets/images/games/forget_me_not/forget_me_not.png"
import Orbital from "../../assets/images/games/orbital/boss attack.png"
import RickOShea from "../../assets/images/games/rick_o_shea/rick_o_shea.png"
import RunAndGun from "../../assets/images/games/run_and_gun/run_and_gun-ui_design-0.png"
import WordsAttack from "../../assets/images/games/words_attack/words_attack.png"
import LoopyBird from "../../assets/images/games/loopy_bird/countryBG.png"
import Miscellaneous from "../../assets/images/games/misc/character_design-21.png"
import { Game } from "../types"

// ADVENTURE DOGS
import AD0 from "../../assets/images/games/adventure_dogs/adventure_dogs_crystal.png"
import AD1 from "../../assets/images/games/adventure_dogs/adventure_dogs-0.png"
import AD2 from "../../assets/images/games/adventure_dogs/dog_cam_2022_06_23.png"
import AD3 from "../../assets/images/games/adventure_dogs/dog_cam_grass_2022_06_30.png"
import AD4 from "../../assets/images/games/adventure_dogs/font_render_old.png"
import AD5 from "../../assets/images/games/adventure_dogs/grass_shader_1.png"
import AD6 from "../../assets/images/games/adventure_dogs/gameplay_adventure_dogs.mp4"
import AD6Thumb from "../../assets/images/games/adventure_dogs/videoThumb.png"

// ATLANTIS WORLD
import MI0 from "../../assets/images/games/minimally_invasive/minimally_invasive_0.png"
import MI1 from "../../assets/images/games/minimally_invasive/minimally_invasive_1.png"
import MI2 from "../../assets/images/games/minimally_invasive/minimally_invasive_2.png"
import MI3 from "../../assets/images/games/minimally_invasive/minimally_invasive_3.png"
import MI4 from "../../assets/images/games/minimally_invasive/minimally_invasive_4.png"
import MI5 from "../../assets/images/games/minimally_invasive/minimally_invasive_5.png"
import MI6 from "../../assets/images/games/minimally_invasive/minimally_invasive_6.png"
import MI7 from "../../assets/images/games/minimally_invasive/minimally_invasive_7.png"
import MI8 from "../../assets/images/games/minimally_invasive/minimally_invasive_8.png"

// ATLANTIS WORLD
import AW0 from "../../assets/images/games/atlantis_world/atlantis map rough draft.png"
import AW1 from "../../assets/images/games/atlantis_world/atlantis_world-level_design-5.png"
import AW2 from "../../assets/images/games/atlantis_world/atlantis_world-level_design-7.png"
import AW3 from "../../assets/images/games/atlantis_world/atlantis_world-level_design-8.png"
import AW4 from "../../assets/images/games/atlantis_world/atlantis_world-level_design-9.png"
import AW5 from "../../assets/images/games/atlantis_world/atlantis_world-level_design-10.png"
import AW6 from "../../assets/images/games/atlantis_world/atlantis_world-ui_design-1.png"
import AW7 from "../../assets/images/games/atlantis_world/atlantisMap-v2.png"
import AW8 from "../../assets/images/games/atlantis_world/TRAILER_OP_Quest.mp4"
import AW8Thumb from "../../assets/images/games/atlantis_world/videoThumb.png"

// BEAM
import B0 from "../../assets/images/games/beam/1.png"
import B1 from "../../assets/images/games/beam/beam-level_design.png"
import B2 from "../../assets/images/games/beam/2.png"
import B3 from "../../assets/images/games/beam/gameplay_beam.mp4"
import B3Thumb from "../../assets/images/games/beam/videoThumb.png"

// BLOCK MAGIC
import BM0 from "../../assets/images/games/block_magic/block_magic-level_design-0.png"
import BM1 from "../../assets/images/games/block_magic/block_magic-level_design-1.png"
import BM2 from "../../assets/images/games/block_magic/block_magic-level_design-2.png"
import BM3 from "../../assets/images/games/block_magic/block_magic-planning.png"
import BM4 from "../../assets/images/games/block_magic/block_magic-planning-0.png"
import BM5 from "../../assets/images/games/block_magic/gameplay_block_magic.mp4"
import BM5Thumb from "../../assets/images/games/block_magic/videoThumb.png"

// BUSHIDO
import BU0 from "../../assets/images/games/bushido/bushido-animating.png"
import BU1 from "../../assets/images/games/bushido/bushido-animating-2.png"
import BU2 from "../../assets/images/games/bushido/bushido-character_modeling.png"
import BU3 from "../../assets/images/games/bushido/bushido-character_modeling-0.png"
import BU4 from "../../assets/images/games/bushido/bushido-character_modeling-1.png"
import BU5 from "../../assets/images/games/bushido/bushido-character_rigging-1.png"
import BU6 from "../../assets/images/games/bushido/bushido-character_rigging-2.png"
import BU7 from "../../assets/images/games/bushido/bushido-character_rigging-6.png"
import BU8 from "../../assets/images/games/bushido/bushido-character_rigging-7.png"
import BU9 from "../../assets/images/games/bushido/bushido-concept.png"
import BU10 from "../../assets/images/games/bushido/bushido-concept-2.png"
import BU11 from "../../assets/images/games/bushido/bushido-concept-4.png"
import BU12 from "../../assets/images/games/bushido/bushido-concept-6.png"
import BU13 from "../../assets/images/games/bushido/bushido-concept-7.png"
import BU14 from "../../assets/images/games/bushido/bushido-concept-8.png"
import BU15 from "../../assets/images/games/bushido/bushido-concept-9.png"
import BU16 from "../../assets/images/games/bushido/bushido-concept-10.png"
import BU17 from "../../assets/images/games/bushido/bushido-game_design.png"
import BU18 from "../../assets/images/games/bushido/bushido-level_design.png"
import BU19 from "../../assets/images/games/bushido/bushido-level_design-4.png"
import BU20 from "../../assets/images/games/bushido/bushido-level_design-5.png"
import BU21 from "../../assets/images/games/bushido/bushido-level_design-8.png"
import BU22 from "../../assets/images/games/bushido/bushido-level_design-9.png"
import BU23 from "../../assets/images/games/bushido/bushido-modeling-2.png"
import BU24 from "../../assets/images/games/bushido/bushido-normal_lighting.png"
import BU25 from "../../assets/images/games/bushido/bushido-normal_mapping.png"
import BU26 from "../../assets/images/games/bushido/bushido-normal_mapping-0.png"
import BU27 from "../../assets/images/games/bushido/bushido-normal_mapping-1.png"
import BU28 from "../../assets/images/games/bushido/bushido-normal_mapping-2.png"
import BU29 from "../../assets/images/games/bushido/bushido-normal_mapping-3.png"
import BU30 from "../../assets/images/games/bushido/bushido-planning-0.png"
import BU31 from "../../assets/images/games/bushido/bushido-planning-1.png"
import BU32 from "../../assets/images/games/bushido/bushido-planning-2.png"
import BU33 from "../../assets/images/games/bushido/bushido-planning-3.png"
import BU34 from "../../assets/images/games/bushido/bushido-sculpting-1.png"
import BU35 from "../../assets/images/games/bushido/bushido-sculpting-2.png"
import BU36 from "../../assets/images/games/bushido/bushido-sculpting-3.png"
import BU37 from "../../assets/images/games/bushido/bushido-sculpting-4.png"
import BU38 from "../../assets/images/games/bushido/bushido-sculpting-5.png"
import BU39 from "../../assets/images/games/bushido/bushido-sculpting-6.png"
import BU40 from "../../assets/images/games/bushido/bushido-sculpting-8.png"
import BU41 from "../../assets/images/games/bushido/bushido-shader.png"
import BU42 from "../../assets/images/games/bushido/bushido-texture_mapping.png"
import BU44 from "../../assets/images/games/bushido/bushido-texture_paint.png"
import BU45 from "../../assets/images/games/bushido/bushido-texture_paint-0.png"
import BU46 from "../../assets/images/games/bushido/bushido-texture_paint-1.png"
import BU47 from "../../assets/images/games/bushido/bushido-texture_paint-3.png"
import BU48 from "../../assets/images/games/bushido/bushido-tool.png"
import BU49 from "../../assets/images/games/bushido/bushido-tools.png"
import BU50 from "../../assets/images/games/bushido/bushido-tools-0.png"
import BU51 from "../../assets/images/games/bushido/bushido-ui-1.png"
import BU52 from "../../assets/images/games/bushido/bushido-ui-4.png"
import BU53 from "../../assets/images/games/bushido/bushido-ui-5.png"
import BU54 from "../../assets/images/games/bushido/new_chibi_redesign.png"

// RECOLLECT
import R0 from "../../assets/images/games/recollect/recollect-level_design.png"
import R1 from "../../assets/images/games/recollect/recollect-ui.png"
import R2 from "../../assets/images/games/recollect/screen1.png"
import R4 from "../../assets/images/games/recollect/screen3.png"
import R5 from "../../assets/images/games/recollect/gameplay_recollect.mp4"
import R5Thumb from "../../assets/images/games/recollect/videoThumb.png"

// SUPER SKY HERO
import SSH0 from "../../assets/images/games/super_sky_hero/attacker_iteration_1.png"
import SSH1 from "../../assets/images/games/super_sky_hero/bar cart layout.png"
import SSH2 from "../../assets/images/games/super_sky_hero/bomber_iteration_1.png"
import SSH3 from "../../assets/images/games/super_sky_hero/bulletSpeedTest.gif"
import SSH4 from "../../assets/images/games/super_sky_hero/fighter_3d_spin.gif"
import SSH5 from "../../assets/images/games/super_sky_hero/fighter_flame_test.gif"
import SSH6 from "../../assets/images/games/super_sky_hero/fighter_iteration_2.png"
import SSH7 from "../../assets/images/games/super_sky_hero/fighter_parts.png"
import SSH8 from "../../assets/images/games/super_sky_hero/fighter_tilts_preview.gif"
import SSH9 from "../../assets/images/games/super_sky_hero/kami_iteration_2.png"
import SSH10 from "../../assets/images/games/super_sky_hero/mech_iteration_2.png"
import SSH11 from "../../assets/images/games/super_sky_hero/mech_iteration_2_1.png"
import SSH12 from "../../assets/images/games/super_sky_hero/multiple_ships_iteration_1.png"
import SSH13 from "../../assets/images/games/super_sky_hero/multiple_ships_size_comparison_1.png"
import SSH14 from "../../assets/images/games/super_sky_hero/Screen Shot 2016-02-26 at 12.21.09 PM.png"
import SSH15 from "../../assets/images/games/super_sky_hero/siteLogo2-longer-background.gif"
import SSH16 from "../../assets/images/games/super_sky_hero/super_sky_hero-tools-0.png"
import SSH17 from "../../assets/images/games/super_sky_hero/super_sky_hero-tools-1.png"
import SSH18 from "../../assets/images/games/super_sky_hero/super_sky_hero-tools-2.png"
import SSH19 from "../../assets/images/games/super_sky_hero/super_sky_hero-tools-4.png"
import SSH20 from "../../assets/images/games/super_sky_hero/super_sky_hero-tools-5.png"
import SSH21 from "../../assets/images/games/super_sky_hero/superSkyHero-boxart.png"
import SSH22 from "../../assets/images/games/super_sky_hero/superSkyHero-boxart-draft-3.png"
import SSH23 from "../../assets/images/games/super_sky_hero/turret_gun.png"
import SSH24 from "../../assets/images/games/super_sky_hero/turret_slices_rotation_demo.gif"
import SSH25 from "../../assets/images/games/super_sky_hero/gameplay_ssh.mp4"
import SSH25Thumb from "../../assets/images/games/super_sky_hero/videoThumb1.png"

// CATASTROPHE
import C0 from "../../assets/images/games/catastrophe/bottom.png"
import C1 from "../../assets/images/games/catastrophe/cat_tomb7(1).png"
import C2 from "../../assets/images/games/catastrophe/deadie_die0.png"
import C3 from "../../assets/images/games/catastrophe/middle.png"
import C4 from "../../assets/images/games/catastrophe/minild35_1.png"
import C5 from "../../assets/images/games/catastrophe/screenShot2.png"
import C6 from "../../assets/images/games/catastrophe/top_left.png"

// LAMBINGS
import L0 from "../../assets/images/games/lambings/sheep (1).png"
import L1 from "../../assets/images/games/lambings/sheep1.png"
import L2 from "../../assets/images/games/lambings/sheep2.png"
import L3 from "../../assets/images/games/lambings/sheepAshes.gif"
import L4 from "../../assets/images/games/lambings/sheepBlink.gif"
import L5 from "../../assets/images/games/lambings/sheepBomb.gif"
import L6 from "../../assets/images/games/lambings/sheepfall.gif"
import L7 from "../../assets/images/games/lambings/sheepWalk.gif"

// RICK O SHEA
import ROS0 from "../../assets/images/games/rick_o_shea/rick_oshea-level_design-0.png"
import ROS1 from "../../assets/images/games/rick_o_shea/rick_oshea-level_design-1.png"
import ROS2 from "../../assets/images/games/rick_o_shea/gameplay_ricochet.mp4"
import ROS2Thumb from "../../assets/images/games/rick_o_shea/videoThumb.png"

// ORBITAL
import O0 from "../../assets/images/games/orbital/alienHead.png"
import O1 from "../../assets/images/games/orbital/armory.png"
import O2 from "../../assets/images/games/orbital/bathrooms.png"
import O3 from "../../assets/images/games/orbital/Blueprint_TopView_Bridge-01.jpg"
import O4 from "../../assets/images/games/orbital/Blueprint_TopView_CrewQuarters-01.jpg"
import O5 from "../../assets/images/games/orbital/Blueprint_TopView_EngineFloor-01.jpg"
import O6 from "../../assets/images/games/orbital/Blueprint_TopView_EscapeBott-01.jpg"
import O7 from "../../assets/images/games/orbital/Blueprint_TopView_LabObservatory-01.jpg"
import O8 from "../../assets/images/games/orbital/Blueprint_TopView_LoungeLoad-01.jpg"
import O9 from "../../assets/images/games/orbital/Blueprint_TopView_MedCryo-01.jpg"
import O10 from "../../assets/images/games/orbital/boss attack.png"
import O11 from "../../assets/images/games/orbital/boss stand.png"
import O12 from "../../assets/images/games/orbital/bottom floor.jpg"
import O13 from "../../assets/images/games/orbital/bridge.jpg"
import O14 from "../../assets/images/games/orbital/cafateria.png"
import O15 from "../../assets/images/games/orbital/cafetria rec room.jpg"
import O16 from "../../assets/images/games/orbital/cargo(1).png"
import O17 from "../../assets/images/games/orbital/cockpit(1).png"
import O18 from "../../assets/images/games/orbital/conferance.png"
import O19 from "../../assets/images/games/orbital/crew_quarters.jpg"
import O20 from "../../assets/images/games/orbital/cryo.jpg"
import O21 from "../../assets/images/games/orbital/cryo_backup.png"
import O22 from "../../assets/images/games/orbital/cryoroom_medical_armory.jpg"
import O23 from "../../assets/images/games/orbital/engine room starbord.jpg"
import O24 from "../../assets/images/games/orbital/engine room(1).png"
import O25 from "../../assets/images/games/orbital/engine room.png"
import O26 from "../../assets/images/games/orbital/hallway(1).png"
import O27 from "../../assets/images/games/orbital/infermary.png"
import O28 from "../../assets/images/games/orbital/lab_observatory.jpg"
import O29 from "../../assets/images/games/orbital/outpost b1.png"
import O30 from "../../assets/images/games/orbital/outpost b2.png"
import O31 from "../../assets/images/games/orbital/outpost b3 -1.png"
import O32 from "../../assets/images/games/orbital/PagePanels_Armory.jpg"
import O33 from "../../assets/images/games/orbital/PagePanels_ArmoryHall.jpg"
import O34 from "../../assets/images/games/orbital/PagePanels_BridgeBack.jpg"
import O35 from "../../assets/images/games/orbital/PagePanels_BridgeFront.jpg"
import O36 from "../../assets/images/games/orbital/PagePanels_Cafeteria.jpg"
import O37 from "../../assets/images/games/orbital/PagePanels_Cryo.jpg"
import O38 from "../../assets/images/games/orbital/PagePanels_EscapeTop.jpg"
import O39 from "../../assets/images/games/orbital/PagePanels_EscapeTopBack.jpg"
import O40 from "../../assets/images/games/orbital/PagePanels_Fork.jpg"
import O41 from "../../assets/images/games/orbital/PagePanels_Lab.jpg"
import O42 from "../../assets/images/games/orbital/PagePanels_LoadingBay.jpg"
import O43 from "../../assets/images/games/orbital/PagePanels_MasterResized_armory.png"
import O44 from "../../assets/images/games/orbital/PagePanels_MedBay.jpg"
import O45 from "../../assets/images/games/orbital/PagePanels_Observatory.jpg"
import O46 from "../../assets/images/games/orbital/PagePanels_PortBack.jpg"
import O47 from "../../assets/images/games/orbital/PagePanels_PortFront.jpg"
import O48 from "../../assets/images/games/orbital/PagePanels_StarboardBack.jpg"
import O49 from "../../assets/images/games/orbital/PagePanels_StarboardFront.jpg"
import O50 from "../../assets/images/games/orbital/pantry(1).png"
import O51 from "../../assets/images/games/orbital/plant room.png"
import O52 from "../../assets/images/games/orbital/scarab.png"
import O53 from "../../assets/images/games/orbital/Ship_cryoRoom.png"
import O54 from "../../assets/images/games/orbital/sketches2.jpg"
import O55 from "../../assets/images/games/orbital/storage.png"
import O56 from "../../assets/images/games/orbital/top floor.jpg"
import O57 from "../../assets/images/games/orbital/turret.png"
import O58 from "../../assets/images/games/orbital/utility(1).png"
import O59 from "../../assets/images/games/orbital/gameplay_1.png"

// RUN AND GUN
import RAG0 from "../../assets/images/games/run_and_gun/run_and_gun-ui_design.png"
import RAG1 from "../../assets/images/games/run_and_gun/run_and_gun-ui_design-1.png"
import RAG2 from "../../assets/images/games/run_and_gun/run_and_gun-ui_design-2.png"
import RAG3 from "../../assets/images/games/run_and_gun/run_and_gun_demo.gif"

// Tricker Trigger
import TT0 from "../../assets/images/games/tricker_trigger/1 - Copy.jpg"
import TT1 from "../../assets/images/games/tricker_trigger/1.jpg"
import TT2 from "../../assets/images/games/tricker_trigger/2.jpg"
import TT3 from "../../assets/images/games/tricker_trigger/battle ui wip 1.4.png"
import TT4 from "../../assets/images/games/tricker_trigger/clinic graveyard.png"
import TT5 from "../../assets/images/games/tricker_trigger/combatHudRecolorTest.png"
import TT6 from "../../assets/images/games/tricker_trigger/concept art tol ship.png"
import TT7 from "../../assets/images/games/tricker_trigger/concrete stone.png"
import TT8 from "../../assets/images/games/tricker_trigger/cyberpunk car 0.png"
import TT9 from "../../assets/images/games/tricker_trigger/cyberpunk car 1.png"
import TT10 from "../../assets/images/games/tricker_trigger/main-char-anim1-test.gif"
import TT11 from "../../assets/images/games/tricker_trigger/main-char-anim2-test.gif"
import TT12 from "../../assets/images/games/tricker_trigger/main-char-anim3-test.gif"
import TT13 from "../../assets/images/games/tricker_trigger/main-char-anim4-test.gif"
import TT14 from "../../assets/images/games/tricker_trigger/main-char-anim5-test.gif"
import TT15 from "../../assets/images/games/tricker_trigger/main-char-anim5-test-2.gif"
import TT16 from "../../assets/images/games/tricker_trigger/main-char-anim6-test.gif"
import TT17 from "../../assets/images/games/tricker_trigger/main-char-anim7-test.gif"
import TT18 from "../../assets/images/games/tricker_trigger/main-char-anim8-test.gif"
import TT19 from "../../assets/images/games/tricker_trigger/main-char-anim9-test.gif"
import TT20 from "../../assets/images/games/tricker_trigger/main-char-intro-anim.gif"
import TT21 from "../../assets/images/games/tricker_trigger/main-char-ko.gif"
import TT22 from "../../assets/images/games/tricker_trigger/main-char-stance-shift.gif"
import TT23 from "../../assets/images/games/tricker_trigger/menu background with overlay wip.png"
import TT24 from "../../assets/images/games/tricker_trigger/police station helipad.png"
import TT25 from "../../assets/images/games/tricker_trigger/police-station-post-combat-0.png"
import TT26 from "../../assets/images/games/tricker_trigger/police-station-post-combat-1.png"
import TT27 from "../../assets/images/games/tricker_trigger/police-station-pre-combat-0.png"
import TT28 from "../../assets/images/games/tricker_trigger/police-station-pre-combat-1.png"
import TT29 from "../../assets/images/games/tricker_trigger/suburbs-destroyed-mannor-0.png"
import TT30 from "../../assets/images/games/tricker_trigger/suburbs-destroyed-mannor-1.png"
import TT31 from "../../assets/images/games/tricker_trigger/tricker_trigger-ui_design.png"
import TT32 from "../../assets/images/games/tricker_trigger/tricker_trigger-ui_design-0.png"
import TT33 from "../../assets/images/games/tricker_trigger/tricker_trigger-ui_design-1.png"
import TT34 from "../../assets/images/games/tricker_trigger/tricker_trigger-ui_design-2.png"
import TT35 from "../../assets/images/games/tricker_trigger/tricker_trigger-ui_design-3.png"
import TT36 from "../../assets/images/games/tricker_trigger/TT-Fakeout-test.gif"
import TT37 from "../../assets/images/games/tricker_trigger/TT-HighRiser-test.gif"
import TT38 from "../../assets/images/games/tricker_trigger/TT-MC-jabrush-test.gif"
import TT39 from "../../assets/images/games/tricker_trigger/TT-slayer-murderStrike-test.gif"
import TT40 from "../../assets/images/games/tricker_trigger/TT-slayer-pommelStroke-test.gif"
import TT41 from "../../assets/images/games/tricker_trigger/TT-slayer-quickSlice-test.gif"
import TT42 from "../../assets/images/games/tricker_trigger/TT-slayer-quickSlice2-test.gif"
import TT43 from "../../assets/images/games/tricker_trigger/TT-Sparren-bulletStab-test.gif"
import TT44 from "../../assets/images/games/tricker_trigger/TT-Sparren-Cascade-edit-test.gif"
import TT45 from "../../assets/images/games/tricker_trigger/TT-Sparren-Cascade-edit-test2.gif"
import TT46 from "../../assets/images/games/tricker_trigger/TT-Sparren-cascade-test.gif"
import TT47 from "../../assets/images/games/tricker_trigger/TT-Sparren-unload-test.gif"
import TT48 from "../../assets/images/games/tricker_trigger/TT_character-display.png"
import TT49 from "../../assets/images/games/tricker_trigger/TT_combat hud A.png"
import TT50 from "../../assets/images/games/tricker_trigger/TT_iconsOutlines.png"

// WORDS ATTACK
import WA0 from "../../assets/images/games/words_attack/letter_shooter-planning.png"
import WA1 from "../../assets/images/games/words_attack/word_attack-planning.png"
import WA2 from "../../assets/images/games/words_attack/word_attack-planning-0.png"
import WA3 from "../../assets/images/games/words_attack/words_attack_demo.gif"

// WRONG FLOOR
import WF0 from "../../assets/images/games/wrong_floor/1.png"
import WF1 from "../../assets/images/games/wrong_floor/2.png"
import WF2 from "../../assets/images/games/wrong_floor/3.png"
import WF3 from "../../assets/images/games/wrong_floor/gameplay_wrong_floor.mp4"
import WF3Thumb from "../../assets/images/games/wrong_floor/videoThumb.png"

// LOOPY BIRD
import LB0 from "../../assets/images/games/loopy_bird/countryBG.png"
import LB1 from "../../assets/images/games/loopy_bird/countryMedal.png"
import LB2 from "../../assets/images/games/loopy_bird/crazyBird_0-1.png"
import LB3 from "../../assets/images/games/loopy_bird/crazyMedal.png"
import LB4 from "../../assets/images/games/loopy_bird/fireBird_2-1.png"
import LB5 from "../../assets/images/games/loopy_bird/fireMedal.png"
import LB6 from "../../assets/images/games/loopy_bird/miniBird_0-1.png"
import LB7 from "../../assets/images/games/loopy_bird/scaryBird_0-1.png"
import LB8 from "../../assets/images/games/loopy_bird/spaceBird_2-1.png"
import LB9 from "../../assets/images/games/loopy_bird/speedyBird_0-1.png"
import LB10 from "../../assets/images/games/loopy_bird/volcanoBG.png"

// MISCELLANEOUS
import M from "../../assets/images/games/misc/character_design.png"
import M0 from "../../assets/images/games/misc/character_design-0.png"
import M1 from "../../assets/images/games/misc/character_design-1.png"
import M2 from "../../assets/images/games/misc/character_design-2.png"
import M3 from "../../assets/images/games/misc/character_design-4.png"
import M4 from "../../assets/images/games/misc/character_design-5.png"
import M5 from "../../assets/images/games/misc/character_design-6.png"
import M6 from "../../assets/images/games/misc/character_design-10.png"
import M7 from "../../assets/images/games/misc/character_design-11.png"
import M8 from "../../assets/images/games/misc/character_design-13.png"
import M9 from "../../assets/images/games/misc/character_design-14.png"
import M10 from "../../assets/images/games/misc/character_design-16.png"
import M11 from "../../assets/images/games/misc/character_design-17.png"
import M12 from "../../assets/images/games/misc/character_design-18.png"
import M13 from "../../assets/images/games/misc/character_design-19.png"
import M14 from "../../assets/images/games/misc/character_design-20.png"
import M15 from "../../assets/images/games/misc/character_design-21.png"
import M16 from "../../assets/images/games/misc/character_design-22.png"
import M17 from "../../assets/images/games/misc/concept-1.png"
import M18 from "../../assets/images/games/misc/concept-3.png"
import M19 from "../../assets/images/games/misc/concept-4.png"
import M20 from "../../assets/images/games/misc/concept-6.png"
import M21 from "../../assets/images/games/misc/FAT THOR TRANSFORMATION.png"
import M22 from "../../assets/images/games/misc/Fat Thor.png"
import M23 from "../../assets/images/games/misc/Justin Sketches.png"
import M24 from "../../assets/images/games/misc/man sketch.png"
import M25 from "../../assets/images/games/misc/mcReady.png"
import M26 from "../../assets/images/games/misc/MONSTER TALES2019-01.png"
import M27 from "../../assets/images/games/misc/prototyping-0.png"
import M28 from "../../assets/images/games/misc/shader_graph-0.png"
import M29 from "../../assets/images/games/misc/sketches-2.png"
import M30 from "../../assets/images/games/misc/sketches-3.png"
import M31 from "../../assets/images/games/misc/sketches-4.png"
import M32 from "../../assets/images/games/misc/sketches.jpg"
import M33 from "../../assets/images/games/misc/sketches1.jpg"
import M34 from "../../assets/images/games/misc/spaceproblems.png"
import M35 from "../../assets/images/games/misc/storyboarding-0.png"
import M36 from "../../assets/images/games/misc/storyboarding-1.png"
import M37 from "../../assets/images/games/misc/storyboarding-2.png"
import M38 from "../../assets/images/games/misc/substance-shader.png"
import M39 from "../../assets/images/games/misc/Thor.png"
import M40 from "../../assets/images/games/misc/ui_design.png"
import M41 from "../../assets/images/games/misc/Untitled-1.png"
import M42 from "../../assets/images/games/misc/waterfall_logo.png"
import M43 from "../../assets/images/games/misc/witchMagik.png"
import M44 from "../../assets/images/games/misc/witchMagikOutline.png"

export const gamesData: Game[] = [
    {
        name: "Minimally Invasive",
        image: MinimallyInvasive,
        genre: "vr surgery",
        description: "A VR game involving the main character shrinking down and going into alien bodies to perform surgeries. Set it a grity cosmic future with a comic style.",
        gallery: [
            {
                type: "image",
                image: MI0,
                caption: "front entrance to organic dungeon",
                thumb: undefined,
            },
            {
                type: "image",
                image: MI1,
                caption: "intestinal cave",
                thumb: undefined,
            },
            {
                type: "image",
                image: MI2,
                caption: "patrol organ boss room",
                thumb: undefined,
            },
            {
                type: "image",
                image: MI3,
                caption: "intestinal walls",
                thumb: undefined,
            },
            {
                type: "image",
                image: MI4,
                caption: "nodules scattered in dry room",
                thumb: undefined,
            },
            {
                type: "image",
                image: MI5,
                caption: "View from under the acid",
                thumb: undefined,
            },
            {
                type: "image",
                image: MI6,
                caption: "blueprint overview of demo level",
                thumb: undefined,
            },
            {
                type: "image",
                image: MI7,
                caption: "purge room",
                thumb: undefined,
            },
            {
                type: "image",
                image: MI8,
                caption: "boss room spline paths",
                thumb: undefined,
            },
        ],
        featured: true,
    },
    {
        name: "Atlantis World",
        image: AtlantisWorld,
        genre: "web3 metaverse",
        description: "A place where web3 is gamified and connects many major web3 protocols and NFT clubhouses. It is also a collaborative place to hold events, give out prizes, complete sponsored quests, or just video chat with some friends from all over.",
        gallery: [
            {
                type: "image",
                image: AW0,
                caption: "sketch of possible world map",
                thumb: undefined,
            },
            {
                type: "image",
                image: AW1,
                caption: "shot of Defi Town showing Perpetual and Balancer buildings",
                thumb: undefined,
            },
            {
                type: "image",
                image: AW2,
                caption: "another shot of Defi Town in front of Pool Together Building",
                thumb: undefined,
            },
            {
                type: "image",
                image: AW3,
                caption: "Defi Town fireplace chat area",
                thumb: undefined,
            },
            {
                type: "image",
                image: AW4,
                caption: "Yearn building in Defi Town",
                thumb: undefined,
            },
            {
                type: "image",
                image: AW5,
                caption: "bridge connecting Atlantis Core map to Defi Town",
                thumb: undefined,
            },
            {
                type: "image",
                image: AW6,
                caption: "loading screen UI",
                thumb: undefined,
            },
            {
                type: "image",
                image: AW7,
                caption: "finalized Atlantis World Map",
                thumb: undefined,
            },
            {
                type: "video",
                image: AW8,
                caption: "trailer for the Optimism Month quest",
                thumb: AW8Thumb,
            }
        ],
        featured: true,
    },
    {
        name: "Super Sky Hero",
        image: SuperSkyHero,
        genre: "adventure shooter",
        description: "A fast paced dynamic action shooter. Play as a member of Rocket Team as he fights to defeat the evil forces trying to destroy the city. Fly through levels avoiding shots and defeating enemy ships. You have the abilty to jump out of you ships, run across floating platforms, and even pull enemy pilots from their own ships and fly them as your own.",
        gallery: [
            {
                type: "image",
                image: SSH0,
                caption: " attacker ship redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH1,
                caption: "box cart train concept pixel art",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH2,
                caption: "bomber ship design",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH3,
                caption: "testing bullet shooting speed",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH4,
                caption: "spinning fighter ship",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH5,
                caption: "ship engine flame test",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH6,
                caption: "fighter ship redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH7,
                caption: "fighter ship exploded",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH8,
                caption: "fighter ship tilt animation",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH9,
                caption: "kami ship redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH10,
                caption: "mech redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH11,
                caption: "alt mech design",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH12,
                caption: "ship redesign side comparisons",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH13,
                caption: "alt view redesign comparisons",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH14,
                caption: "player trajectory debug",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH15,
                caption: "logo animated banner",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH16,
                caption: "custom flight path creator",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH17,
                caption: "ship flight pattern",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH18,
                caption: "bullet collision test",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH19,
                caption: "level enemy attack sequence",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH20,
                caption: "enemy attack sequences",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH21,
                caption: "box art sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH22,
                caption: "finished box art",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH23,
                caption: "turret gun design",
                thumb: undefined,
            },
            {
                type: "image",
                image: SSH24,
                caption: "turret slice rotation",
                thumb: undefined,
            },
            {
                type: "video",
                image: SSH25,
                caption: "gameplay footage",
                thumb: SSH25Thumb,
            }
        ],
        featured: true,
    },
    {
        name: "Tricker Triger",
        image: TrickerTrigger,
        genre: "fantasy rpg",
        description: "JRPG set in a post apocalyptical future. Full of mutated monsters, intricate combo mechanics, and a unique take on RPS battle system.",
        // link: "https://supergamebros.itch.io/rockoshea",
        gallery: [
            {
                type: "image",
                image: TT0,
                caption: "Sparren painted render",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT1,
                caption: "Hellhound painted render",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT2,
                caption: "Main Char painted render",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT3,
                caption: "in game batte ui test",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT4,
                caption: "clinic graveyard storyboard",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT5,
                caption: "battle menu ui design",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT6,
                caption: "tol ship concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT7,
                caption: "stylized concrete stone texture",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT8,
                caption: "cyber punk car concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT9,
                caption: "other cyber punk car concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT10,
                caption: "main char combo test - 1",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT11,
                caption: "main char combo test - 2",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT12,
                caption: "main char combo test - 3",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT13,
                caption: "main char combo test - 4",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT14,
                caption: "main char combo test - 5",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT15,
                caption: "main char combo test - 5 - 2",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT16,
                caption: "main char combo test - 6",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT17,
                caption: "main char combo test - 7",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT18,
                caption: "main char combo test - 8",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT19,
                caption: "main char combo test - 9",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT20,
                caption: "main char intro anim",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT21,
                caption: "main char ko anim",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT22,
                caption: "main char stance shift anim",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT23,
                caption: "main char combo test - menu design test",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT24,
                caption: "police station helipad storyboard",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT25,
                caption: "police station post combat storyboard",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT26,
                caption: "police station post combat - 2 storyboard",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT27,
                caption: "police station pre combat storyboard",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT28,
                caption: "police station pre combat - 2 storyboard",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT29,
                caption: "destroyed mannor combat start storyboard",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT30,
                caption: "destroyed mannor combat start - 2 storyboard",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT31,
                caption: "designing battle ui",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT32,
                caption: "player hud ui",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT33,
                caption: "designing menu player windows",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT34,
                caption: "menu ui design mockups",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT35,
                caption: "menu ui design mockups - 2",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT36,
                caption: "fakeout animation reference",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT37,
                caption: "hgih riser animation reference",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT38,
                caption: "jab rush animation reference",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT39,
                caption: "murder strike animation reference",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT40,
                caption: "pommel strike animation reference",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT41,
                caption: "quick slice animation reference",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT42,
                caption: "quick slice alt animation reference",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT43,
                caption: "bullet stab animation reference",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT44,
                caption: "cascade animation reference",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT45,
                caption: "cascade alt animation reference",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT46,
                caption: "cascade fix animation reference",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT47,
                caption: "unloaded animation reference",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT48,
                caption: "player hud revised",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT49,
                caption: "battle ui revision test",
                thumb: undefined,
            },
            {
                type: "image",
                image: TT50,
                caption: "menu ui icons sketches",
                thumb: undefined,
            },
            
        ],
        featured: true,
    },
    {
        name: "Beam",
        image: Beam,
        genre: "adventure puzzle",
        description: "A level based reflective laser puzzle where you must use the special blocks and your mirrored self to get the light all the way to the trigger spot at the end of the room.",
        gallery: [
            {
                type: "image",
                image: B0,
                caption: "overhead shot of level one",
                thumb: undefined,
            },
            {
                type: "image",
                image: B1,
                caption: "overhead shot of level two",
                thumb: undefined,
            },
            {
                type: "image",
                image: B2,
                caption: "overhead shot of level three",
                thumb: undefined,
            },
            {
                type: "video",
                image: B3,
                caption: "gameplay footage",
                thumb: B3Thumb,
            }
        ],
        featured: true,
    },
    {
        name: "Wrong Floor",
        image: WrongFloor,
        genre: "strategy puzzle",
        description: "A giant robot is terrorizing your building. Stay out of sight and make your way out of this crazy situation. But be careful because the building is falling apart as you try to escape.",
        gallery: [
            {
                type: "image",
                image: WF0,
                caption: "making it to the exit of the level",
                thumb: undefined,
            },
            {
                type: "image",
                image: WF1,
                caption: "selecting from range of movement",
                thumb: undefined,
            },
            {
                type: "image",
                image: WF2,
                caption: "hiding from oncoming laser attack",
                thumb: undefined,
            },
            {
                type: "video",
                image: WF3,
                caption: "gameplay footage",
                thumb: WF3Thumb,
            }
        ],
        featured: true,
    },
    {
        name: "Block Magic",
        image: BlockMagic,
        genre: "puzzle adventure",
        description: "A level based puzzle game that gives you a special skill to get over water by creating walkable platforms. However, this has its limitations and you must learn how to make it to the end of the room without fallling into the water.",
        gallery: [
            {
                type: "image",
                image: BM0,
                caption: "testing of game mechanics",
                thumb: undefined,
            },
            {
                type: "image",
                image: BM1,
                caption: "view of level 3",
                thumb: undefined,
            },
            {
                type: "image",
                image: BM2,
                caption: "view of level 4",
                thumb: undefined,
            },
            {
                type: "image",
                image: BM3,
                caption: "level design sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: BM4,
                caption: "another level design planning sketch",
                thumb: undefined,
            },
            {
                type: "video",
                image: BM5,
                caption: "gameplay footage",
                thumb: BM5Thumb,
            }
        ],
        featured: true,
    },
    {
        name: "Adventure Dogs",
        image: AdventureDogs,
        genre: "infinite runner",
        description: "In this game you control an ever growing dog pack that expands every time you correctly interact with every new doggie visitor. How big can you get your dog pack?",
        gallery: [
            {
                type: "image",
                image: AD0,
                caption: "dog viewing a hidden power crystal in the distance",
                thumb: undefined,
            },
            {
                type: "image",
                image: AD1,
                caption: "pack of dogs interacting with a new dog to join pack",
                thumb: undefined,
            },
            {
                type: "image",
                image: AD2,
                caption: "day night cycle in horizon",
                thumb: undefined,
            },
            {
                type: "image",
                image: AD3,
                caption: "dog idle looking at screen test",
                thumb: undefined,
            },
            {
                type: "image",
                image: AD4,
                caption: "custom model and text renderer",
                thumb: undefined,
            },
            {
                type: "image",
                image: AD5,
                caption: "testing new grass shader to improve performance",
                thumb: undefined,
            },
            {
                type: "video",
                image: AD6,
                caption: "gameplay footage",
                thumb: AD6Thumb,
            }
        ],
        featured: true,
    },
    {
        name: "Bushido",
        image: Bushido,
        genre: "tactical rpg",
        description: "An epic story that transcends empires and timelines. At the heart there is an interesting tactical time based strategy fighting mechanic that is challenging and fast paced. But, displayed simple enough to learn quickly",
        gallery: [
            {
                type: "image",
                image: BU0,
                caption: "running main character animation",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU1,
                caption: "walking keyframes of main character",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU2,
                caption: "sculpted main char broken into separate pieces",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU3,
                caption: "low poly modeling based on high poly model",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU4,
                caption: "low poly body base",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU5,
                caption: "bipedal rig",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU6,
                caption: "bipedal rig controller",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU7,
                caption: "skinning rig to low poly model",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU8,
                caption: "testing rig skinning",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU9,
                caption: "city concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU10,
                caption: "house concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU11,
                caption: "hut concept drawing",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU12,
                caption: "fence concept drawing",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU13,
                caption: "hot spring concept painting",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU14,
                caption: "character classes concept drawing",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU15,
                caption: "standard character human male concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU16,
                caption: "mountain top concept painting",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU17,
                caption: "battle design planning sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU18,
                caption: "stylized bridge being placed in map",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU19,
                caption: "level design of samurai village",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU20,
                caption: "map for testing battle mechanics",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU21,
                caption: "world map terrain editing",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU22,
                caption: "samurai village level design",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU23,
                caption: "testing house visuals",
                thumb: undefined,
            },
            // {
            //     type: "image",
            //     image: BU24,
            //     caption: "modeling house",
            //     thumb: undefined,
            // },
            {
                type: "image",
                image: BU24,
                caption: "baking lighting on to model",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU25,
                caption: "baked normals from high poly into low poly",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU26,
                caption: "testing high poly normals and shader",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU27,
                caption: "playing with high poly lighting",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU28,
                caption: "unwraping high poly model",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU29,
                caption: "testing normal mapping",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU30,
                caption: "battle gameplay planning",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU31,
                caption: "planning battle mechanics",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU32,
                caption: "planning see through mechanics",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU33,
                caption: "planning collision caging",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU34,
                caption: "sculpting player head",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU35,
                caption: "finished sculpted head",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU36,
                caption: "finished sculpting full player body",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU37,
                caption: "back view of sculpted body",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU38,
                caption: "close up of body torso",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU39,
                caption: "sculpting hair with nodes",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU40,
                caption: "finishing sculpting hair",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU41,
                caption: "water shader testing",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU42,
                caption: "unwrapping building walls",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU44,
                caption: "painting hut model",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU45,
                caption: "painting step ladder model",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU46,
                caption: "grass tile painting",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU47,
                caption: "painting base player head",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU48,
                caption: "testing see trhough mechanic",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU49,
                caption: "testing player collision cage",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU50,
                caption: "testing see trhough mechanic and collision cage",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU51,
                caption: "ui redesign sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU52,
                caption: "testing battle mechanics",
                thumb: undefined,
            },
            {
                type: "image",
                image: BU53,
                caption: "battle ui redesign",
                thumb: undefined,
            },
            // {
            //     type: "image",
            //     image: BU55,
            //     caption: "character concept sketch",
            // },
        ],
        featured: true,
    },
    {
        name: "Recollect",
        image: Recollect,
        genre: "adventure",
        description: "A touching story told in an interactive format. Go through the life of this complicated boy as he uncovers hidden memories",
        gallery: [
            {
                type: "image",
                image: R0,
                caption: "level one view",
                thumb: undefined,
            },
            {
                type: "image",
                image: R1,
                caption: "item ui",
                thumb: undefined,
            },
            {
                type: "image",
                image: R2,
                caption: "looking for your lost dog",
                thumb: undefined,
            },
            {
                type: "image",
                image: R4,
                caption: "playing by the swings",
                thumb: undefined,
            },
            {
                type: "video",
                image: R5,
                caption: "gameplay footage",
                thumb: R5Thumb,
            }
        ],
        featured: false,
    },
    {
        name: "Catastrophe",
        image: Catastrophe,
        genre: "Sungeon Crawler",
        description: "Explore this ancient cave once ruled by our cat overlords. Be on the lookout for undead feline mummies. But not to worry, your trusty bow and arrow can take care of them.",
        gallery: [
            {
                type: "image",
                image: C0,
                caption: "bottom of level",
                thumb: undefined,
            },
            {
                type: "image",
                image: C1,
                caption: "cat tomb design",
                thumb: undefined,
            },
            {
                type: "image",
                image: C2,
                caption: "enemy mummy design",
                thumb: undefined,
            },
            {
                type: "image",
                image: C3,
                caption: "middle floor level design",
                thumb: undefined,
            },
            {
                type: "image",
                image: C4,
                caption: "player entering cave",
                thumb: undefined,
            },
            {
                type: "image",
                image: C5,
                caption: "player exploring cave",
                thumb: undefined,
            },
            {
                type: "image",
                image: C6,
                caption: "top floor of level",
                thumb: undefined,
            }
        ],
        featured: false,
    },
    {
        name: "Lambings",
        image: Lambings,
        genre: "strategy puzzle",
        description: "You are a sheep hearder with many sheep at your disposal, each with its own skill. Use them all to your advantage to solve the puzzles in your way.",
        // link: "",
        gallery: [
            {
                type: "image",
                image: L0,
                caption: "sheep concept designs",
                thumb: undefined,
            },
            {
                type: "image",
                image: L1,
                caption: "alt sheep 1",
                thumb: undefined,
            },
            {
                type: "image",
                image: L2,
                caption: "alt sheep 2",
                thumb: undefined,
            },
            {
                type: "image",
                image: L3,
                caption: "sheep death animation",
                thumb: undefined,
            },
            {
                type: "image",
                image: L4,
                caption: "sheep idle animation",
                thumb: undefined,
            },
            {
                type: "image",
                image: L5,
                caption: "sheep explode countdown animation",
                thumb: undefined,
            },
            {
                type: "image",
                image: L6,
                caption: "sheep falling animation",
                thumb: undefined,
            },
            {
                type: "image",
                image: L7,
                caption: "wheep waling animation",
                thumb: undefined,
            }
        ],
        featured: true,
    },
    {
        name: "Rick O Shea",
        image: RickOShea,
        genre: "text adventure",
        description: "we created a character named Rick O'Shea, a detective on a case to figure out how 3 murders could have happened in one night, and only one murder had a bullet. Can you solve the case?",
        // link: "https://supergamebros.itch.io/rockoshea",
        gallery: [
            {
                type: "image",
                image: ROS0,
                caption: "screenshot of park level",
                thumb: undefined,
            },
            {
                type: "image",
                image: ROS1,
                caption: "screenshot of arcade level",
                thumb: undefined,
            },
            {
                type: "video",
                image: ROS2,
                caption: "gameplay footage",
                thumb: ROS2Thumb,
            }
        ],
        featured: false,
    },
    {
        name: "Orbital",
        image: Orbital,
        genre: "text adventure",
        description: "You wake up from cryosleep. You dont have any memory. Trying to find anyone to answer the many questions you have seems to be very difficult. Something happened here but you dont know what. Make your way through the seemingly abandoned ship to get your answers. But even though youi might not feel it, you are not alone",
        // link: "https://supergamebros.itch.io/rockoshea",
        gallery: [
            {
                type: "image",
                image: O0,
                caption: "laboratory table view",
                thumb: undefined,
            },
            {
                type: "image",
                image: O1,
                caption: "armory room sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: O2,
                caption: "bathroom sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: O3,
                caption: "ship blueprint - bridge",
                thumb: undefined,
            },
            {
                type: "image",
                image: O4,
                caption: "ship blueprint - crew quarters",
                thumb: undefined,
            },
            {
                type: "image",
                image: O5,
                caption: "ship blueprint - engine floor",
                thumb: undefined,
            },
            {
                type: "image",
                image: O6,
                caption: "ship blueprint - bottom escape",
                thumb: undefined,
            },
            {
                type: "image",
                image: O7,
                caption: "ship blueprint - lab observatory",
                thumb: undefined,
            },
            {
                type: "image",
                image: O8,
                caption: "ship blueprint - lounge",
                thumb: undefined,
            },
            {
                type: "image",
                image: O9,
                caption: "ship blueprint - medical cryo",
                thumb: undefined,
            },
            {
                type: "image",
                image: O10,
                caption: "boss attacking",
                thumb: undefined,
            },
            {
                type: "image",
                image: O11,
                caption: "boss standing",
                thumb: undefined,
            },
            {
                type: "image",
                image: O12,
                caption: "bottom escape concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: O13,
                caption: "bridge concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: O14,
                caption: "cafeteria colored panel",
                thumb: undefined,
            },
            {
                type: "image",
                image: O15,
                caption: "cafeteria concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: O16,
                caption: "cargo room colored panel",
                thumb: undefined,
            },
            {
                type: "image",
                image: O17,
                caption: "cockpit colored panel",
                thumb: undefined,
            },
            {
                type: "image",
                image: O18,
                caption: "conference room colored panel",
                thumb: undefined,
            },
            {
                type: "image",
                image: O19,
                caption: "crew quarters",
                thumb: undefined,
            },
            {
                type: "image",
                image: O20,
                caption: "cryo view outline",
                thumb: undefined,
            },
            {
                type: "image",
                image: O21,
                caption: "cryo colored panel",
                thumb: undefined,
            },
            {
                type: "image",
                image: O22,
                caption: "cryo floor concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: O23,
                caption: "engine room concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: O24,
                caption: "engine room colored panel",
                thumb: undefined,
            },
            {
                type: "image",
                image: O25,
                caption: "engine room panel sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: O26,
                caption: "hallway view",
                thumb: undefined,
            },
            {
                type: "image",
                image: O27,
                caption: "infirmary colored panel",
                thumb: undefined,
            },
            {
                type: "image",
                image: O28,
                caption: "laboratory observatory concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: O29,
                caption: "outpost b1 panel sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: O30,
                caption: "outpost b2 panel sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: O31,
                caption: "outpost b3 panel sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: O32,
                caption: "armory view redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: O33,
                caption: "armory hall redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: O34,
                caption: "bridge back redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: O35,
                caption: "bridge front redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: O36,
                caption: "cafeteria view redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: O37,
                caption: "cryo room redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: O38,
                caption: "top escape redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: O39,
                caption: "escape top back view redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: O40,
                caption: "fork path view redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: O41,
                caption: "laboratory view redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: O42,
                caption: "loading bay view redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: O43,
                caption: "armory fully colored panel redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: O44,
                caption: "med bay view redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: O45,
                caption: "observatory view redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: O46,
                caption: "engine port back view redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: O47,
                caption: "engine port front view redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: O48,
                caption: "engine starboard back view redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: O49,
                caption: "engine starboard front view redesign",
                thumb: undefined,
            },
            {
                type: "image",
                image: O50,
                caption: "pantry colored panel",
                thumb: undefined,
            },
            {
                type: "image",
                image: O51,
                caption: "plant room panel sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: O52,
                caption: "scarab enemy colored",
                thumb: undefined,
            },
            {
                type: "image",
                image: O53,
                caption: "cryo room redesign colored",
                thumb: undefined,
            },
            {
                type: "image",
                image: O54,
                caption: "enemy concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: O55,
                caption: "storage panel sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: O56,
                caption: "top floor concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: O57,
                caption: "turret panel sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: O58,
                caption: "utility room colored panel",
                thumb: undefined,
            },
            {
                type: "image",
                image: O59,
                caption: "gameplay screenshot",
                thumb: undefined,
            }
        ],
        featured: false,
    },
    {
        name: "Run And Gun",
        image: RunAndGun,
        genre: "infinite runner",
        description: "we created a character named Rick O'Shea, a detective on a case to figure out how 3 murders could have happened in one night, and only one murder had a bullet. Can you solve the case?",
        // link: "https://supergamebros.itch.io/rockoshea",
        gallery: [
            {
                type: "image",
                image: RAG0,
                caption: "building out fame ove screen",
                thumb: undefined,
            },
            {
                type: "image",
                image: RAG1,
                caption: "game over screen",
                thumb: undefined,
            },
            {
                type: "image",
                image: RAG2,
                caption: "designing landing page",
                thumb: undefined,
            },
            {
                type: "image",
                image: RAG3,
                caption: "demo of game",
                thumb: undefined,
            },
        ],
        featured: true,
    },
    {
        name: "Words Attack",
        image: WordsAttack,
        genre: "word shooter",
        description: "Uncscramble the words by shooting the bouncing letters in the right order. A fun fast paced web game",
        // link: "https://supergamebros.itch.io/rockoshea",
        gallery: [
            {
                type: "image",
                image: WA0,
                caption: "planning gameplay sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: WA1,
                caption: "planning gameplay sketch 2",
                thumb: undefined,
            },
            {
                type: "image",
                image: WA2,
                caption: "planning gameplay sketch 3",
                thumb: undefined,
            },
            {
                type: "image",
                image: WA3,
                caption: "gameplay demo",
                thumb: undefined,
            }
        ],
        featured: false,
    },
    {
        name: "Loopy Bird",
        image: LoopyBird,
        genre: "mobile arcade",
        description: "tap to fly in bursts, make your way through the obstacles without getting hit. See how far you can go!",
        // link: "https://supergamebros.itch.io/rockoshea",
        gallery: [
            {
                type: "image",
                image: LB0,
                caption: "country background",
                thumb: undefined,
            },
            {
                type: "image",
                image: LB1,
                caption: "country map medal",
                thumb: undefined,
            },
            {
                type: "image",
                image: LB2,
                caption: "crazy bird",
                thumb: undefined,
            },
            {
                type: "image",
                image: LB3,
                caption: "crazy medal",
                thumb: undefined,
            },
            {
                type: "image",
                image: LB4,
                caption: "fire bird",
                thumb: undefined,
            },
            {
                type: "image",
                image: LB5,
                caption: "fire medal",
                thumb: undefined,
            },
            {
                type: "image",
                image: LB6,
                caption: "mini bird",
                thumb: undefined,
            },
            {
                type: "image",
                image: LB7,
                caption: "scary bird",
                thumb: undefined,
            },
            {
                type: "image",
                image: LB8,
                caption: "space bird",
                thumb: undefined,
            },
            {
                type: "image",
                image: LB9,
                caption: "speedy bird",
                thumb: undefined,
            },
            {
                type: "image",
                image: LB10,
                caption: "volcano background",
                thumb: undefined,
            }
        ],
        featured: false,
    },
    {
        name: "Miscellaneous",
        image: Miscellaneous,
        genre: "many",
        description: "Here is some game work that is not for any particular game and is just concept things we have created.",
        // link: "https://supergamebros.itch.io/rockoshea",
        gallery: [
            {
                type: "image",
                image: M,
                caption: "enemy slime concept",
                thumb: undefined,
            },
            {
                type: "image",
                image: M0,
                caption: "hero concept",
                thumb: undefined,
            },
            {
                type: "image",
                image: M1,
                caption: "bird sayan concept",
                thumb: undefined,
            },
            {
                type: "image",
                image: M2,
                caption: "close up of bird sayan",
                thumb: undefined,
            },
            {
                type: "image",
                image: M3,
                caption: "tony stark character study",
                thumb: undefined,
            },
            {
                type: "image",
                image: M4,
                caption: "tony stark character study - colored",
                thumb: undefined,
            },
            {
                type: "image",
                image: M5,
                caption: "character concept",
                thumb: undefined,
            },
            {
                type: "image",
                image: M6,
                caption: "cyber samurai concept art",
                thumb: undefined,
            },
            {
                type: "image",
                image: M7,
                caption: "random guy concept",
                thumb: undefined,
            },
            {
                type: "image",
                image: M8,
                caption: "mutated shark concept",
                thumb: undefined,
            },
            {
                type: "image",
                image: M9,
                caption: "bird sayan colored",
                thumb: undefined,
            },
            {
                type: "image",
                image: M10,
                caption: "chibi dr. strange",
                thumb: undefined,
            },
            {
                type: "image",
                image: M11,
                caption: "donald darko concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: M12,
                caption: "donald darko concept colored",
                thumb: undefined,
            },
            {
                type: "image",
                image: M13,
                caption: "dr strange character portrait study",
                thumb: undefined,
            },
            {
                type: "image",
                image: M14,
                caption: "dr strange character portrait study - base painting started",
                thumb: undefined,
            },
            {
                type: "image",
                image: M15,
                caption: "dr strange character portrait study - fully painted",
                thumb: undefined,
            },
            {
                type: "image",
                image: M16,
                caption: "character study painting",
                thumb: undefined,
            },
            {
                type: "image",
                image: M17,
                caption: "in game ad concept painting study",
                thumb: undefined,
            },
            {
                type: "image",
                image: M18,
                caption: "future car concept",
                thumb: undefined,
            },
            {
                type: "image",
                image: M19,
                caption: "in game company logo mock up",
                thumb: undefined,
            },
            {
                type: "image",
                image: M20,
                caption: "in game painting study",
                thumb: undefined,
            },
            {
                type: "image",
                image: M21,
                caption: "thor characters colored",
                thumb: undefined,
            },
            {
                type: "image",
                image: M22,
                caption: "fat thor concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: M23,
                caption: "character styles study",
                thumb: undefined,
            },
            {
                type: "image",
                image: M24,
                caption: "character concept",
                thumb: undefined,
            },
            {
                type: "image",
                image: M25,
                caption: "mcready concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: M26,
                caption: "in game company logo mock up",
                thumb: undefined,
            },
            {
                type: "image",
                image: M27,
                caption: "web trivia game prototyping",
                thumb: undefined,
            },
            {
                type: "image",
                image: M28,
                caption: "gibli tree shader",
                thumb: undefined,
            },
            {
                type: "image",
                image: M29,
                caption: "alien enemey concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: M30,
                caption: "cover art concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: M31,
                caption: "cover art concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: M32,
                caption: "cutscene idea sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: M33,
                caption: "action scene concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: M34,
                caption: "emotional scene concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: M35,
                caption: "intro animation concept storyboard",
                thumb: undefined,
            },
            {
                type: "image",
                image: M36,
                caption: "intro animation concept storyboard - 2",
                thumb: undefined,
            },
            {
                type: "image",
                image: M37,
                caption: "intro animation concept storyboard - 3",
                thumb: undefined,
            },
            {
                type: "image",
                image: M38,
                caption: "brick shader",
                thumb: undefined,
            },
            {
                type: "image",
                image: M39,
                caption: "thor sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: M40,
                caption: "ui layout for concept game",
                thumb: undefined,
            },
            {
                type: "image",
                image: M41,
                caption: "character concept design",
                thumb: undefined,
            },
            {
                type: "image",
                image: M42,
                caption: "in game company logo mock up",
                thumb: undefined,
            },
            {
                type: "image",
                image: M43,
                caption: "witch profile concept sketch",
                thumb: undefined,
            },
            {
                type: "image",
                image: M44,
                caption: "witch profile concept outlining",
                thumb: undefined,
            },
        ],
        featured: false,
    },
]