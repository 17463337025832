import { FormControl, InputAdornment, TextField } from "@mui/material";
import { FC, ReactElement, useState } from "react";
import { Email } from "@mui/icons-material";
let validator = require('validator')

export const EmailInput: FC<any> = ({ value, setValue }): ReactElement => {

    const [isValid, setIsValid] = useState<boolean>(false)

    const [currentValue, setCurrentValue] = useState<string>("")

    return(
        <FormControl>

            <TextField
                id="contact-email"
                label="email"
                type="email"
                InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Email color="secondary" />
                    </InputAdornment>
                ),
                }}
                onChange={(e: any) => {

                    setValue(e)

                    if( e.target.value !== "" ) {

                        setIsValid(validator.isEmail(e.target.value))

                        setCurrentValue(e.target.value)

                    }

                }}
                placeholder={value}
                required
                sx={{
                    marginBottom: "10px",
                    width: "300px",
                }}
                error={ currentValue !== "" && !isValid }
                helperText={
                    currentValue !== "" && !isValid
                    ? "please enter valid email"
                    : false
                }
            />

        </FormControl>
        
    )
    
}