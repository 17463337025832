import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FC, ReactElement } from "react";
import { Game } from "../../data/types";
import { Thumb } from "./ImagesDisplay";

export const GamesDisplay:FC<any  > = ({
    allGames,
    gotoGame,
    gameIndex,
}: {
    allGames: Game[],
    gotoGame: Function,
    gameIndex: number
}):ReactElement => {

    const allGamesDisplay = allGames.map((game: Game, index: number) => {
        return(
            <Box
                key={index}
            >

                <Thumb
                    image={game.image}
                    alt={game.name}
                    index={index}
                    onclick={gotoGame}
                    size="lg"
                    selectedIndex={gameIndex}
                    thumb={undefined}
                />

            </Box>
        )
    })

    return(
        <Box
            sx={{
                display: "relative",
                marginTop: "-50px",
                zIndex: 3
            }}
        >

            <Typography
                color="primary.main"
                textAlign="center"
                sx={{
                    paddingBottom: "10px"
                }}
            >
                all games
            </Typography>

            <Stack
                direction="row"
                gap="5px"
                maxWidth="1300px"
                flexWrap="wrap"
                justifyContent="center"
                sx={{
                    paddingBottom: "2%"
                }}
            >

                {allGamesDisplay}

            </Stack>
        
        
        </Box>
        
    )

}