import { Box } from "@mui/material";
import { FC, ReactElement, useRef } from "react";
import { Contact } from "../components/contact/Contact";
import { HomeAbout } from "../components/home/HomeAbout";
import { HomeHero } from "../components/home/HomeHero";
import { HomeTenets } from "../components/home/HomeTenets";
import { companyInfo } from "../data/companyInfo";
import SinTeimpoLogo from "../assets/svg/sin_tiempo-logo-icon.svg"
import { HomeServices } from "../components/home/HomeServices";
import { HomeSquad } from "../components/home/HomeSquad";
import { HomeClients } from "../components/home/HomeClients";

export const Home: FC<any> = ({navRef}): ReactElement => {

    const contactRef = useRef(null)
    
    const heroRef = useRef(null)

    return(
        <Box>
            
            <Box
                ref={heroRef}
            >

                <HomeHero contactRef={contactRef} />

            </Box>

            <HomeTenets />

            <HomeServices />

            <HomeAbout />

            <HomeClients />

            <HomeSquad />

            <Box
                ref={contactRef}
            >

                <Contact
                    contactInfo={companyInfo}
                    ImageSrc={SinTeimpoLogo}
                    navRef={navRef}
                />

            </Box>

        </Box>
    )
}