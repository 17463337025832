import { Box, Button, Container, Stack, Typography, } from "@mui/material"
import { FC, ReactElement } from "react"
import { ContactForm } from "./ContactForm"
import { ContactInfo } from "./ContactInfo"
import { CurvedSegment } from "../dividers/CurvedSegment"
import "../../styles/backgroundPatterns.css"

export const Contact: FC<any> = ({ contactInfo, ImageSrc, navRef }): ReactElement => {

    return(
        <Box
            sx={{
                // backgroundColor: "primary.contrastText",
                paddingTop: "10%",
                paddingBottom: "5%",
                position: "relative",
                // height: "580px",
                backgroundColor: "#000000",
                backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%23220000' points='1600 160 0 460 0 350 1600 50'/%3E%3Cpolygon fill='%23440000' points='1600 260 0 560 0 450 1600 150'/%3E%3Cpolygon fill='%23660000' points='1600 360 0 660 0 550 1600 250'/%3E%3Cpolygon fill='%23880000' points='1600 460 0 760 0 650 1600 350'/%3E%3Cpolygon fill='%23A00' points='1600 800 0 800 0 750 1600 450'/%3E%3C/g%3E%3C/svg%3E")`,
                backgroundAttachment: "fixed",
                backgroundSize: "cover",
            }}
        >

            <CurvedSegment
                direction="bottom"
                color="white"
                marTop="0px"
                marBottom="0px"
                zInd={2}
                snapTo="top"
            />

            <Box
                sx={{
                    // position: "absolute",
                    // zIndex: 1,
                    paddingTop: "3%",
                    width: "100%",
                }}
            >

                {/* <Typography
                    color="primary.light"
                    textAlign="center"
                    fontSize="1.5rem"
                    marginBottom="10px"
                >
                    CONTACT
                </Typography> */}

                <Typography
                    color="primary.light"
                    textAlign="center"
                    fontSize=".9rem"
                    maxWidth="600px"
                    marginX="auto"
                    marginBottom="20px"
                >
                    💡 Feel free to contact us and ask us any quiestions you might have or if you are ready for us to get to work on your next title. We look forward to working together. 🤝
                </Typography>

                <Container
                    sx={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                    }}
                >

                    <ContactForm
                        contactInfo={contactInfo}
                    />

                    <ContactInfo
                        contactInfo={contactInfo}
                        ImageSrc={ImageSrc}
                    />

                </Container>

            </Box>

            <Stack
                width="100%"
                justifyContent="center"
                alignItems="center"
                sx={{
                    marginTop: "50px"
                }}
            >

                <Button
                    onClick={() => {
                        console.log("scrolling to top")
                        navRef.current.scrollIntoView()
                        // document.body.scrollTo({top: 0})
                    }}
                    sx={{
                        backgroundColor: "secondary.light",
                        margin: "0px",
                        border: "3px solid darkred",
                        ":hover": {
                            border: "3px solid orange",
                            backgroundColor: "primary.dark",
                            color: "primary.light"
                        }
                    }}
                >
                    Back To Top
                </Button>

            </Stack>

            

        </Box>
    )
}