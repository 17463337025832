import AW from "../../assets/images/work/aw.png"
import SN from "../../assets/images/work/sketchy_neurons.png"
import CodedSnake from "../../assets/images/work/coded_snake.png"
import Empoc from "../../assets/images/work/empoc.png"
import SGB from "../../assets/images/work/SGB.png"

export const workData = [
    {
        name: "Sketchy Neurons",
        image: SN,
        link: "https://sketchyneurons.com/",
        description: "A VR game involving the main character shrinking down and going into alien bodies to perform surgeries. Set it a grity cosmic future with a comic style.",
        details:
            {
                name: "Full Game Development",
                tasks: [
                    "Reimagine game concept to focus direction",
                    "Create an engaging VR demo using existing mechanics",
                    "Assist and guide Development teams in game production"
                ],
                services: [
                    "Game design",
                    "Level design ",
                    "Project Management",
                    "3d modeling",
                    "Texturing",
                    "Programming",
                ]
            },
    },
    {
        name: "Atlantis World",
        image: AW,
        link: "https://atlantis.world",
        description: "A social / collaborative metaverse meant to bring together Web3 teams as well as community members by offering integrated social features and web3 functionality seamlessly.",
        details:
            {
                name: "Full Game Development",
                tasks: [
                    "To create the main game that players would interact with, that was both web and mobile friendly.",
                    "Build a multiplayer system capable of handling hundreds of simultaneous players.",
                    "Integrate social features such as voice and video chat."
                ],
                services: [
                    "Game design",
                    "Level design ",
                    "Project Management",
                    "Texturing",
                    "Programming",
                    "Web Design"
                ]
            },
    },
    {
        name: "CodedSnake",
        image: CodedSnake,
        link: "https://www.codedsnake.com/",
        description: "Tricker Trigger: JRPG set in a post apocalyptical future. Full of mutated monsters, intricate combo mechanics, and a unique take on RPS battle system.",
        details:
            {
                name: "2D Art & Animation",
                tasks: [
                    "Concept Art for scenery and in world objects",
                    "Reference drawings for 3D animations",
                    "Storyboarding for cutscene animations",
                    "UI assets for menus and player hud",
                ],
                services: [
                    "2D & 3D Animating",
                    "Story Boarding",
                    "3d modeling",
                    "Texturing",
                ]
            },
    },
    {
        name: "Empoc LLC",
        image: Empoc,
        link: "https://www.linkedin.com/company/empoc-llc/about/",
        description: "Apple App Store App and Game developer. Creator of the most popular Flappy Bird clone on iOS.",
        details:
            {
                name: "Game Development",
                tasks: [
                    "Pixel art sprites for all of the unlockable birds.",
                    "Background pixel art for unlockable levels.",
                    "Balance Gameplay"
                ],
                services: [
                    "Game design",
                    "Level design ",
                    "2D Art",
                    "Programming",
                ]
            },
    },
    {
        name: "Super Game Bros",
        image: SGB,
        link: "https://www.youtube.com/@supergamebros_",
        description: "",
        details:
            {
                name: "Full Game Development",
                tasks: [
                    "Created games based on random idea generation",
                    "Game jam style building process",
                    "managing community server",
                    "created dev update videos"
                ],
                services: [
                    "Game design",
                    "Level design ",
                    "Project Management",
                    "3d modeling",
                    "Texturing",
                    "Programming",
                    "Web Design",
                ]
            },
    }
]