import { Box, Button, Container, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { FC, ReactElement, useState } from "react"
// import HomeHeroBG from "../../assets/jpg/home-hero_placeholder.jpg"
import HomeHeroBG from "../../assets/gifs/hero_test_0.gif"
import { companyInfo } from "../../data/companyInfo"
import { CurvedSegment } from "../dividers/CurvedSegment"
import Reel from "../../assets/video/highlight_2023_03_09.mp4"
import { AssetModal } from "../AssetModal"

export const HomeHero: FC<any> = ({contactRef}): ReactElement => {
    
    const [modalOpen, setModalOpen] = useState(false)

    return(
        <Box
            sx={{
                // width: "100%",
                position: "relative",
                height: "500px",
                zIndex: 0,
                backgroundImage: `url(${HomeHeroBG})`,
                backgroundAttatchment: "fixed",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom",
                backgroundSize: "cover",
            }}
        >

            <Container
                maxWidth="xl"
                sx={{
                    zIndex: 2,
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "500px",
                    left: "0px",
                    right: "0px",
                    margin: "auto",
                    gap: "20px",
                    backgroundColor: "rgba(3,3,3,0.5)",
                }}
            >

                <Typography
                    fontSize="4rem"
                    color="primary.light"
                    fontWeight="bold"
                    textAlign="center"
                    lineHeight="4rem"
                    sx={{
                        textShadow: "0px 2px 0px #FB601C"
                    }}
                >
                    {`" ${companyInfo.missionStatement} "`}
                </Typography>

                <Typography
                    fontSize="2rem"
                    color="secondary.light"
                    fontWeight="bold"
                    textAlign="center"
                    sx={{
                        textShadow: "0px 2px 0px #951B17",
                        letterSpacing: "1px",
                    }}
                >
                    {`${companyInfo.missionStatementSubTitle}`}
                </Typography>

                <Stack
                    direction="row"
                    gap="20px"
                    height="40px"
                    justifyContent="center"
                    alignItems="center"
                >

                    <Box
                        sx={{
                            height: "35px",
                            // backgroundColor: "rgba(3,3,3, 0.5)",
                            backgroundColor: "secondary.light",
                            borderRadius: "10px",
                            transition: "all 1s",
                            cursor: "pointer",
                            color: "primary.dark",
                            padding: "5px 20px",
                            letterSpacing: "1px",
                            ":hover": {
                                backgroundColor: "primary.main",
                                boxShadow: "0px 3px orange",
                                // border: "2px solid orange",
                                color: "primary.light"
                            },
                            boxShadow: "0px 3px orangered"
                        }}
                        onClick={() => setModalOpen(true)}
                    >
                        Watch Showreel
                    </Box>

                    <Box
                        onClick={() => {
                            contactRef.current.scrollIntoView()
                        }}
                        sx={{
                            height: "35px",
                            // backgroundColor: "rgba(3,3,3, 0.5)",
                            backgroundColor: "secondary.main",
                            boxShadow: "0px 3px darkred",
                            borderRadius: "10px",
                            transition: "all 1s",
                            cursor: "pointer",
                            color: "primary.light",
                            padding: "5px 20px",
                            ":hover": {
                                backgroundColor: "primary.main",
                                boxShadow: "0px 3px orange",
                                // border: "2px solid orange",
                            }
                        }}
                    >
                        Contact Us Now
                    </Box>

                </Stack> 

                <AssetModal
                    isOpen={modalOpen}
                    setIsOpen={setModalOpen}
                    assetSrc={Reel}
                />               

            </Container>

            <CurvedSegment
                direction="up"
                color="white"
                marTop="0px"
                marBottom="0px"
                zInd={2}
                snapTo="bottom"
            />

        </Box>
        
    )
}