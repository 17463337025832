import { Card, Stack, Typography, } from "@mui/material";
import { FC, ReactElement } from "react";
import { servicesData, } from "../../data/company/services";
import { Service } from "../../data/types";
import { Box } from "@mui/system";
import { CurvedSegment } from "../dividers/CurvedSegment";
import { CurvedLine } from "../dividers/CurvedLine";

const ServiceCard: FC<any> = ({service, index}): ReactElement => {

    return(

        <Card
            sx={{
                overflow: "hidden",
            }}
        >

            <Box
                sx={{
                    display: "flex",
                    // justifyContent: "start"
                }}
            >

                <img
                    src={service.image}
                    alt={`${service.name} service card`}
                    style={{
                        width: "300px",
                        height: "300px",
                        objectFit: "cover",
                        position: "relative",
                        zIndex: 2,
                    }}
                />

                <Box
                    sx={{
                        width:"25px",
                        height: "300px",
                        // width: "40px",
                        // height: "100%"
                        marginLeft: "-35px",
                        positon: "relative",
                        zIndex: 3,
                        display: "flex",
                        alignItems: "center",
                    }}
                >

                    <CurvedLine
                        direction="left"
                        color="white"
                    />

                </Box>

                <Box
                    sx={{
                        width:"15px",
                        height: "300px",
                        marginLeft: "-5px",
                        positon: "relative",
                        zIndex: 3,
                        display: "flex",
                        alignItems: "center",
                        // backgroundColor: "red",
                    }}
                >

                    <CurvedSegment
                        direction="left"
                        color="white"
                        zIndex={3}
                    />

                </Box>
                

                <Stack
                    sx={{
                        padding: "20px 15px 20px 0px",
                        width: "300px",
                        // justifyContent: "space-between",
                        alignItems: "center",
                        background: "linear-gradient(to right, white, #e1ebfc)"
                    }}
                >

                    <Typography
                        // variant="h5"
                        color="primary.main"
                        fontSize="1.3rem"
                        fontWeight="bold"
                        sx={{
                            marginBottom: "40px"
                        }}
                    >
                        {service.name}
                    </Typography>

                    <Typography
                        color="primary.main"
                        fontSize=".8rem"
                        textAlign="center"
                        sx={{
                            // backgroundColor:"red",
                            maxHeight: "180px",
                            overflowY: "auto",
                        }}
                    >
                        {service.description}
                    </Typography>

                    {/* <Link
                        key={service.name}
                        to={`services/${index}`}
                        component={NavLink}
                        underline="none"
                        sx={{
                            
                        }}
                    >

                        <Button
                            sx={{
                                transition: "all 1s",
                                paddingX: "20px",
                                backgroundColor: "secondary.main",
                                color: "primary.light",
                                ":hover": {
                                        backgroundColor: "primary.dark",
                                        color: "secondary.main",
                                        transform: "scale(1.1)"  
                                }
                            }}
                        >
                            more info
                        </Button>

                    </Link> */}

                </Stack>

            </Box>

        </Card>

    )

}

export const ServicesGallery: FC<any> = ():ReactElement => {

    const servicesDisplay = servicesData.map((service: Service, index: number) => {

        return(
            <Box
                key={index}
            >

                <ServiceCard service={service} index={index} />

            </Box>
        )

    })

    return(
        <Box
            sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                width: "100%",
                padding: "10px",
                justifyContent: "center",
            }}
        >

            {servicesDisplay}

        </Box>
    )

}