import { FC } from "react";
import { ServiceView } from "./components/services/ServiceView";
import { MemberProfile } from "./components/team/MemberProfile";
import { Home } from "./pages/Home";
import { Portfolio } from "./pages/Portfolio";
import { Resources } from "./pages/Resources";
import { Services } from "./pages/Services";
// import { Team } from "./pages/Team";

interface Route {
    key: string,
    title: string,
    path: string,
    enabled: boolean,
    component: any
}

export const routes: Array<Route> = [
    {
        key: 'home-route',
        title: 'Home',
        path: '/',
        enabled: true,
        component: Home
    },
    {
        key: 'team-member-route',
        title: 'Team Member',
        path: '/team/:memberName',
        enabled: true,
        component: MemberProfile
    },
    {
        key: 'services-route',
        title: 'Services',
        path: '/services',
        enabled: true,
        component: Services
    },
    {
        key: 'service-route',
        title: 'Service',
        path: '/services/:serviceId',
        enabled: true,
        component: ServiceView
    },
    {
        key: 'portfolio-route',
        title: 'Portfolio',
        path: '/portfolio/:selectedGameIndex',
        enabled: true,
        component: Portfolio
    },
    {
        key: 'resources-route',
        title: 'Resources',
        path: '/resources',
        enabled: true,
        component: Resources
    },
]

export const links: Array<Route> = [
    {
        key: 'home-route',
        title: 'Home',
        path: '/',
        enabled: true,
        component: Home
    },
    // {
    //     key: 'services-route',
    //     title: 'Services',
    //     path: '/services',
    //     enabled: true,
    //     component: Services
    // },
    {
        key: 'portfolio-route',
        title: 'Portfolio',
        path: '/portfolio/0',
        enabled: true,
        component: Portfolio
    },
    {
        key: 'blog-route',
        title: "Blog",
        path: "https://sintiempo.substack.com/",
        enabled: true,
        component: undefined
    }
    // {
    //     key: 'resources-route',
    //     title: 'Resources',
    //     path: '/resources',
    //     enabled: true,
    //     component: Resources
    // },
]