import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React, { useRef } from "react";
import { theme } from './theme';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { routes as appRoutes } from "./routes";
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';


function App() {

  const navRef = useRef(null)

  return (
    <ThemeProvider theme={theme}>

      <CssBaseline />

      <Box
        height="100vh"
        display="flex"
        flexDirection="column"
        sx={{
          overflowX:"hidden",
        }}
      >

        <Router>

          <Box
            ref={navRef}
            id="navbar"
          >

            <Navbar/>

          </Box>
          
          <Routes>

            {
              appRoutes.map((route) => (
                <Route
                  key={route.key}
                  path={route.path}
                  element={<route.component navRef={navRef} />}
                />
              ))
            }

          </Routes>

          <Footer/>

        </Router>

      </Box>

    </ThemeProvider>
  );
}

export default App;