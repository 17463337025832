export const tenetsData = [
    {
        title: "Integrity",
        message: "Being honest to ourselves and others, as well as respectful."
    },
    {
        title: "Comradery",
        message: "We believe in small, close-knit teams, with aligned principles."
    },
    {
        title: "Passion",
        message: "Every project we commit to deserves to be given our all."
    },
]