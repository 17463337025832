// types
import { gamesData } from "./company/games";
import { servicesData } from "./company/services";
import { socialData } from "./company/social";
import { tenetsData } from "./company/tenets";
import { toolsData } from "./company/tools";
import { workData } from "./company/work";
import { CompanyInfo } from "./types";

export const companyInfo: CompanyInfo = {
    name: "Sin Tiempo LLC",
    location:
        `530-B Harkle Road: STE 100:
        Santa Fe, NM 87505`,
    social: socialData,
    access_key: "68b221b3-7734-4320-af35-3a178bf11d63",
    missionStatement: "Games Should Be Fun & Impactful",
    missionStatementSubTitle: "Let us Help You Reach Your Goal",
    tenets: tenetsData,
    about: "Our aim is to create and share spirited and thought-provoking video game experiences, develop original IP and also partnering with other studios to help bring their visions to life. That requires the right tools.",
    tools: toolsData,
    work: workData,
    games: gamesData,
    services: servicesData,
    // miscGallery: miscGalleryData,
    // portfolio: portfolioData
}