export const toolsData = {
    engines: [
        "unity",
        "unreal",
        "phaser",
        "custom",
    ],
    art: [
        "blender",
        "3ds",
        "substance",
        "aseprite",
        "tiled",
        "photoshop",
    ],
    languages: [
        "c",
        "c++",
        "c#",
        "javascript"
    ]
}