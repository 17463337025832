import { Box, Card, Divider, Link, Typography } from "@mui/material"
import { FC, ReactElement } from "react"
import { socialIcons } from "../../data/icons"
import { SocialLink } from "../../data/types"

const RenderedSocialLinks = ({socialLinksArray}: {socialLinksArray: SocialLink[]}) => {

    const renderedLinks = socialLinksArray.map((socialLink: SocialLink, index: number) => {

        const icon = socialIcons[socialLink.name]

        return(
            <Link
                href={socialLink.link}
                underline="none"
                key={index}
                target="_blank"
            >

                <Box
                    sx={{
                        display: "flex",
                        gap: "5px"
                    }}
                >

                    <Box
                        sx={{
                            color: "secondary.light"
                        }}
                    >
                        
                        { icon }

                    </Box>

                    <Typography
                        color="primary.light"
                        fontSize=".8rem"
                        fontWeight="bold"
                        sx={{
                            ":hover": {
                                color: "secondary.light"
                            }
                        }}
                    >

                        {socialLink.link}

                    </Typography>

                </Box>

            </Link>
            
        )

    })

    return(
        <>

            {renderedLinks}
        
        </>
    )
}

export const ContactInfo: FC<any> = ({contactInfo, ImageSrc}): ReactElement => {

    return(
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                justifyContent: "space-between",
                alignItems: "center",
                width: "300px",
                height: "420px",
            }}
        >

            <Box
                sx={{
                    backgroundColor: "primary.dark",
                    borderRadius: "100%",
                    padding: "10px",
                    width: "240px",
                    height: "280px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >

                <img
                    src={ImageSrc}
                    alt={`${contactInfo.name} contact icon`}
                    style={{
                        // width: "300px"
                    }}
                />

            </Box>

            

            <Card
                sx={{
                    backgroundColor: "primary.dark",
                    
                    width: "300px",
                    // width: "88%",
                    marginTop: "20px",

                }}
            >

                <Typography
                    color="primary.light"
                    textAlign="center"
                    fontSize="1.5rem"
                    fontWeight="bold"
                    sx={{
                        backgroundColor: "secondary.main"
                    }}
                >

                    { contactInfo.name}

                </Typography>

                <Box
                    sx={{
                        padding: "10px 20px",
                    }}
                >

                    <RenderedSocialLinks socialLinksArray={contactInfo.social} />

                </Box>

            </Card>

        </Box>
    )

}