// social icons
import { Email, Web, LinkedIn, Twitter, Newspaper, GroupWork, GitHub } from "@mui/icons-material";

// tool icons
import Unity from "../assets/images/tools/unity.png"
import Unreal from "../assets/images/tools/unreal.png"
import Phaser from "../assets/images/tools/phaser.png"
import Custom from "../assets/images/tools/custom.png"
import Blender from "../assets/images/tools/blender.png"
import ThreeDS from "../assets/images/tools/3dsmax.png"
import Substance from "../assets/images/tools/substance.png"
import Aseprite from "../assets/images/tools/aseprite.jpg"
import Tiled from "../assets/images/tools/tiled.png"
import Photoshop from "../assets/images/tools/photoshop.png"
import C from "../assets/images/tools/language_c.png"
import CPlusPlus from "../assets/images/tools/language_cpp.png"
import CSharp from "../assets/images/tools/language_c#.png"
import Javascript from "../assets/images/tools/language_js.png"

// game icons

const socialIconStyle = {
    fontSize: 20
}

export const socialIcons: any = {
    "email": <Email sx={socialIconStyle} />,
    "website": <Web sx={socialIconStyle} />,
    "linkedin": <LinkedIn sx={socialIconStyle} />,
    "twitter": <Twitter sx={socialIconStyle} />,
    "newsletter": <Newspaper sx={socialIconStyle} />,
    "upwork": <GroupWork sx={socialIconStyle} />,
    "github": <GitHub sx={socialIconStyle} />,
}

export const toolIcons: any = {
    "unity": Unity,
    "unreal": Unreal,
    "phaser": Phaser,
    "custom": Custom,
    "blender": Blender,
    "3ds": ThreeDS,
    "substance": Substance,
    "aseprite": Aseprite,
    "tiled": Tiled,
    "photoshop": Photoshop,
    "c": C,
    "c++": CPlusPlus,
    "c#": CSharp,
    "javascript": Javascript,
}

// export const gameIcons: any = {

// }