import { Box, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { FC, ReactElement } from "react"
import { CurvedSegment } from "./dividers/CurvedSegment"

export const Hero: FC<any> = ({
    bgImage,
    title,
    subtitle,
    description = undefined,
    children = undefined
}): ReactElement => {

    const heroHeight =
        children
        ? "700px"
        : "500px"

    return(
        <Box
            sx={{
                // width: "100%",
                position: "relative",
                height: heroHeight,
                zIndex: 0,
                backgroundImage: `url(${bgImage})`,
                backgroundAttatchment: "fixed",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom",
                backgroundSize: "cover",
            }}
        >

            <Box
                // maxWidth="xl"
                sx={{
                    zIndex: 2,
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: heroHeight,
                    width: "100%",
                    left: "0px",
                    right: "0px",
                    margin: "auto",
                    backgroundColor: "rgba(3,3,3,0.7)",
                    paddingBottom: "5%"
                }}
            >

                <Stack
                    direction="row"
                    justifyContent="space-between"
                    width="800px"
                    alignItems="center"
                >

                    <Typography
                        fontSize="3rem"
                        color="primary.light"
                        fontWeight="bold"
                        textAlign="center"
                        lineHeight="3rem"
                        sx={{
                            textShadow: "0px 2px 0px #FB601C",
                        }}
                    >
                        {`${title}`}
                    </Typography>

                    <Typography
                        fontSize="1.5rem"
                        color="secondary.light"
                        fontWeight="bold"
                        textAlign="center"
                        sx={{
                            textShadow: "0px 2px 0px #951B17",
                            letterSpacing: "1px",
                        }}
                    >
                        {`${subtitle}`}
                    </Typography>

                </Stack>

                <Stack
                    sx={{
                        maxWidth: "800px",
                        height: "80px"
                    }}
                    justifyContent="center"
                >

                    <Typography
                        fontSize=".9rem"
                        color="primary.main"
                        textAlign="center"
                        // fontWeight="bold"
                        sx={{
                            // opacity: 0.8,
                            backgroundColor: "secondary.light",
                            borderRadius: "5px",
                            padding: "5px",
                            marginTop: "8px",
                            boxShadow: "0px 3px orangered"
                        }}
                    >
                        {description}
                    </Typography>

                </Stack>
                
                {children}

            </Box>

            <CurvedSegment
                direction="up"
                color="white"
                marTop="0px"
                marBottom="0px"
                zInd={2}
                snapTo="bottom"
            />

        </Box>
        
    )
}