import { Box, Container, Typography } from "@mui/material"
import { FC, ReactElement, useState } from "react"
import { teamInfo } from "../../data/teamInfo"
import { TeamMember } from "../../data/types"
import { MiniProfile } from "../team/MiniProfile"

export const HomeSquad: FC<any> = (): ReactElement => {

    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined)

    const renderedMiniProfiles = teamInfo.map((member: TeamMember, index: number) => {

        return(
            <Box
                key={index}
                flex={1}
                sx={{
                    width: " 250px",
                    // backgroundColor: "gold",
                }}
            >

                <MiniProfile
                    member={member}
                    index={index}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                />

            </Box>
        )
        
    })

    return(
        <Box
            sx={{
                paddingY: "3%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >

            <Typography
                color="primary.main"
                textAlign="center"
                sx={{
                    marginBottom: "20px"
                }}
            >
                meet the team of skilled individuals
            </Typography>

            <Box
                sx={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "center",
                    flexWrap: "flex",
                    maxWidth: "80%",
                    // backgroundColor: "blue",
                    // height: "900px",
                }}
            >

                {renderedMiniProfiles}

            </Box>

        </Box>
    )
}