import { Box, Link, Stack } from "@mui/material"
import { FC, ReactElement } from "react"
import { socialIcons } from "../../data/icons"
import { SocialLink } from "../../data/types"

export const MiniSocial: FC<any> = ({member}): ReactElement => {

    const socialLinks = member.social.map((social: SocialLink, index: number) => {

        const icon = socialIcons[social.name]

        return(
            <Link
                href={social.link}
                underline="none"
                key={index}
                target="_blank"
            >

                <Box
                    sx={{
                        color: "primary.light",
                        ":hover": {
                            color: "secondary.main"
                        },
                    }}
                >

                    {icon}
                    
                </Box>

            </Link>
        )

    })

    return(
        <Stack
            direction="row"
            gap="10px"
            justifyContent="center"
        >

            {socialLinks}

        </Stack>
    )

}