// pfp images
import pfpJustin from "../assets/jpg/pfp-justin.jpg"
import pfpJulio from "../assets/jpg/pfp-julio.jpg"
import pfpAustyn from "../assets/jpg/pfp-austyn.jpg"

// types
import { TeamMember } from "./types"

export const teamInfo: TeamMember[] = [
    {
        name: "👨‍🚀 Justin Fletcher",
        pfp: pfpJustin,
        title: "Game Designer / Programmer",
        intro: "Game Designer and Programmer with over 10 years of experience in independent game development.",
        bio: "I Began making iOS games in 2011 using Cocos2d, gained extensive experience making games with the Unity game engine, and most recently venturing directly into game engine development.",
        skills: [
            {
                name: "",
                years: 0,
            }
        ],
        social: [
            {
                name: "twitter",
                link: "http://www.twitter.com/superjustin5000",
            },
            {
                name: "website",
                link: "https://www.superjustin.com",
            },
            {
                name: "email",
                link: "jfletch1787@gmail.com",
            },
        ],
        access_key: "e612659c-1b13-4575-9d49-37f76784d5bd",
        location: "Hill Valley",
        handle: "SuperJustin5000"
    },
    {
        name: "🚀 Julio Alcantara",
        pfp: pfpJulio,
        title: "Game Developer / Artist",
        intro: "Game Developer with an eye for aesthetics with over 10 years experience in all kinds of Art creation and Game Programming.",
        bio: "I am skilled in creating traditional and digital artistry, static and animated. I have used these skills in UX/UI design, Branding, Logos, Character designs, reference animations, level design, and Creative Direction for many game projects. This coupled with my time programming in Game Development and use of Game engines makes me a very well rounded member of the team.",
        skills: [
            {
                name: "",
                years: 0,
            }
        ],
        social: [
            {
                name: "github",
                link: "pending",
            },
            {
                name: "linkedin",
                link: "pending",
            },
            {
                name: "website",
                link: "https://www.deadheadstudio.com",
            },
            {
                name: "email",
                link: "julio.a@skiff.com",
            }
        ],
        access_key: "cd856d3c-b049-493a-b059-116a8dfbe4fa",
        location: "Washington, USA",
        handle: "DeadHead",
    },
    {
        name: "🌌 Austyn Studdard",
        pfp: pfpAustyn,
        title: "Game Developer",
        intro: "Hey there! I'm known as Cosmic. I have been developing games for 8 years using Unity, Phaser.js, and a bit of Unreal.",
        bio: "Hey there! I'm Austyn, also known as Cosmic. I have been developing games for 8 years using Unity, Phaser.js, and a bit of Unreal. Recently, I have been exploring Godot and have even dabbled in game engine development. With almost a decade of experience in the game development industry, I am confident that I can create engaging and immersive gaming experiences.",
        skills: [
            {
                name: "",
                years: 0,
            }
        ],
        social: [
            {
                name: "linkedin",
                link: "https://www.linkedin.com/in/austynstuddard/",
            },
            {
                name: "github",
                link: "https://github.com/kingcosmic/",
            },
            {
                name: "website",
                link: "https://austynstuddard.com",
            },
            {
                name: "email",
                link: "contact@austynstuddard.com",
            }
        ],
        access_key: "26277f57-2544-4773-83fb-bbdf3db9a0ba",
        location: "Cosmic Space",
        handle: "KingCosmic",
    },
]