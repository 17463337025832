import { Box, Typography } from "@mui/material"
import { FC, ReactElement, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { teamInfo } from "../../data/teamInfo"
import { TeamMember } from "../../data/types"
import { Hero } from "../Hero"
import { MemberBio } from "./MemberBio"
import { MemberHero } from "./MemberHero"
import { MemberSkills } from "./MemberSkills"
import { MiniSocial } from "./MiniSocial"
// import { withRouter } from 'react-router-dom'

// @ts-ignore
export const MemberProfile: FC<any> = (): ReactElement => {

    const {memberName}: any = useParams()

    // const {
    //     params : { memberId },
    // } = match

    const [isLoading, setIsLoading] = useState<boolean>(true)

    const [member, setMember] = useState<TeamMember | undefined>(undefined)

    useEffect(() => {

        console.log("memebr Name: ", memberName)

        const memberData = teamInfo.filter((member) => {

            const firstName = member.name.split(" ")[1].toLowerCase()

            console.log("first name: ", firstName)

            if( firstName === memberName ) return true

            return false

        })

        // if (memberId !== undefined) {

            setMember(memberData[0])

            setIsLoading(false)

        // }

        console.log("member: ", member)

    }, [memberName])


    return(
        <Box>

            {
                !isLoading && member && (
                    <Box
                        textAlign="center"
                    >

                        <MemberHero
                            member={member}
                        />

                        <MemberBio member={member} />

                        <MemberSkills member={member}/>
                    
                    </Box>
                )
            }
            

        </Box>
    )

}

// export default withRouter(MemberProfile)