import { Avatar, Badge, Box, Button, Card, Divider, Link, styled, Typography } from "@mui/material"
import { FC, ReactElement } from "react"
import { NavLink } from "react-router-dom"
import { MiniSocial } from "./MiniSocial"

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

export const MiniProfile: FC<any> = ({member, index, selectedIndex, setSelectedIndex}): ReactElement => {

    const isSelected = selectedIndex === index

    const bgColor =
        isSelected
        ? "secondary.main"
        : "primary.dark"

    const fontColor =
        isSelected
        ? "primary.light"
        : "primary.light"

    const nameColor =
        isSelected
        ? "primary.dark"
        : "secondary.main"

    return(
        <Box
            sx={{
                transition: "all 1s",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                alignItems: "center",
                // backgroundColor: bgColor,
                // padding: "15px 10px 10px 10px",
                // borderRadius: "120px 120px 10px 10px",
                // borderTopRadius: "50%"
            }}
        >

            {/* <Link
                to={'team'}
                underline="none"
                key="profile"
                component={NavLink}
                type="button"
            > */}

                <Box
                    sx={{
                        border: "5px solid white",
                        borderRadius: "100%",
                        cursor: "pointer",
                        transition: "all 1s",
                        ":hover": {
                            borderColor: "secondary.main",
                            transform: "scale(1.1)"
                        },
                        height: 200,
                        width: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                    }}
                    onMouseEnter={() => {setSelectedIndex(index)}}
                    onMouseLeave={() => {setSelectedIndex(undefined)}}
                >

                    <Avatar
                        src={member.pfp}
                        alt={member.name}
                        sx={{
                            height: 190,
                            width: 190,
                            // border: "3px solid black",
                            // borderColor: "secondary.main",
                            // boxShadow: "3px 3px 0px gold"
                        }}
                    />

                </Box>

            {/* </Link> */}

            <Typography
                textAlign="center"
                sx={{
                    transition: "all 1s",
                    // backgroundColor: "secondary.main",
                    color: nameColor,
                    width: "100%",
                    // borderBottom: "3px solid gold",
                    // textShadow: "0px 1px #FB601C"

                }}
                fontSize="1.5rem"
                fontWeight="bold"
            >
                {member.name}
            </Typography>

            <Card
                sx={{
                    transition: "all 1s",
                    width: "230px",
                    backgroundColor: bgColor,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingBottom: "10px",
                    padding: "10px",
                    height: "150px",
                }}
            >

                <Divider />

                <Typography
                    color="primary.main"
                    fontSize=".8rem"
                    textAlign="center"
                    sx={{
                        transition: "all 1s",
                        // padding: " 10px 20px",
                        height: "110px",
                        // backgroundColor: "red",
                        color: fontColor,
                    }}
                >
                    {member.intro}
                </Typography>

                <MiniSocial member={member} />

                {/* <Link
                    to={`/team/${index}`}
                    underline="none"
                    key="profile"
                    component={NavLink}
                    type="button"
                >

                    <Button
                        sx={{
                            backgroundColor: "secondary.light",
                            // marginTop: "10px",
                            padding: "0px 10px",
                            // margin: "auto",
                            ":hover": {
                                backgroundColor: "secondary.main",
                                color: "primary.light",
                            },
                            color: "primary.dark"
                        }}
                    >
                        view profile
                    </Button>

                </Link> */}

            </Card>

        </Box>
    )
}