import { Service } from "../types"
import FullGameDevelopment from "../../assets/jpg/full_game_development.jpg"
import ProgrammingScripting from "../../assets/jpg/game_programming.jpg"
import Design from "../../assets/jpg/unity-game-design.jpg"
import Prototyping from "../../assets/jpg/game_prototyping.png"
import TwoDArt from "../../assets/jpg/game_2d_art.jpg"
import ThreeDArt from "../../assets/jpg/game_2d_art.jpg"
import Tooling from "../../assets/jpg/game_tool_utility.jpg"
import Porting from "../../assets/jpg/game_porting.jpg"
import QualityAssurance from "../../assets/jpg/game_quality_assurance.jpg"

export const servicesData: Service[] = [
    {
        name: "🕹️ Full Game Development",
        image: FullGameDevelopment,
        description: "Let us develop your game project entirely from scratch, from planning all the way through to post launch. We work with you through the entire process to make sure your expectations for your game are met and exceeded. ",
        breakdown: [
            {
                title: "Planning",
                description: "Working closely with client to define as much of the game as possible, putting together a design plan document.",
            },
            {
                title: "Pre-Production & Prototyping",
                description: "",
            },
            {
                title: "Development Cycle",
                description: "Design, Develop, Test / Feedback",
            },
            {
                title: "Quality Assurance",
                description: "",
            },
            {
                title: "Release & Porting",
                description: "",
            },
        ],
    },
    {
        name: "⌨️ Programming / Scripting",
        image: ProgrammingScripting,
        description: "We have the game programming expertise and experience to meet virtually any of programming needs. Whether you need a specialized system developed, bugs tracked down, or just extra hands on deck, we’re your team. With experience in Unity, Unreal, Phaser, and even proprietary engines.",
        breakdown: [
            {
                title: "Gameplay",
                description: "",
            },
            {
                title: "Collision / Physics",
                description: "",
            },
            {
                title: "Rendering",
                description: "",
            },
            {
                title: "AI Controllers",
                description: "",
            },
            {
                title: "3rd Party Integrations",
                description: "",
            },
            {
                title: "Game Systems",
                description: "",
            },
            {
                title: "Multiplayer Systems",
                description: "",
            },
            {
                title: "Code Architecture / Refactoring",
                description: "",
            },
            {
                title: "Bug Fixing",
                description: "",
            },
            {
                title: "Performance Tuning",
                description: "",
            },
        ],
    },
    {
        name: "📐 Design",
        image: Design,
        description: "Squeeze more creative juice into your games with our design services. We can help you balance and fill the gaps in your game’s design, tweak and tune individual systems, and build fun and engaging levels.",
        breakdown: [
            {
                title: "Gameplay",
                description: "",
            },
            {
                title: "Balance",
                description: "",
            },
            {
                title: "Combat",
                description: "",
            },
            {
                title: "Character",
                description: "",
            },
            {
                title: "Level",
                description: "",
            },
            {
                title: "Documentation",
                description: "",
            },
        ],
    },
    {
        name: "👾 Prototyping",
        image: Prototyping,
        description: "Hammering out a game concept can be an rigorous process. Rapid prototyping has shown to be a useful tool in attempting to hone in on or even come up with a brand new idea. Whether you’re trying to create a fresh gameplay concept, or just tweak and existing one, we can worth you.",
        breakdown: [
            {
                title: "",
                description: "",
            }
        ],
    },
    {
        name: "🎨 Art & Animation",
        image: TwoDArt,
        description: "Game art permeates through every aspect of the game development process, from sprites, characters, animations, special effects, concepts, cutscenes, 3d textures, UI and more. We can help you visually flesh out your world, and even assist in honing in on a specific art style, genre, look and feel.",
        breakdown: [
            {
                title: "Sprites",
                description: "",
            },
            {
                title: "2D / 3D Animations",
                description: "",
            },
            {
                title: "3D Modeling",
                description: "",
            },
            {
                title: "Textures",
                description: "",
            },
            {
                title: "Texture Mapping",
                description: "",
            },
            {
                title: "Storyboarding",
                description: "",
            },
            {
                title: "2D Cutscenes",
                description: "",
            },
            {
                title: "Concept Art",
                description: "",
            },
            {
                title: "Animation Reference",
                description: "",
            },
            {
                title: "UI Assets",
                description: "",
            },
        ],
    },
    // {
    //     name: "3D Art & Animation",
    //     image: ThreeDArt,
    //     description: "",
    //     breakdown: [
    //         {
    //             title: "",
    //             description: "",
    //         }
    //     ],
    // },
    {
        name: "🧰 Tooling / Utilities",
        image: Tooling,
        description: "Having the right level design tools or other custom utilities can greatly increase development productivity. We have experience building fully custom tools from scratch, web tools, extending the Unity editor for your specific project, or even building live debug and editor tools directly into the games themselves.",
        breakdown: [
            {
                title: "",
                description: "",
            }
        ],
    },
    {
        name: "🎮 Porting",
        image: Porting,
        description: "If your looking to branch out and make your game cross platform, we offer services to port your game to any platform we can develop on. PC, Playstation 4/5, Xbox One/Series S, Switch, Web, and even Mobile platforms. Give your players the freedom to choose their favorite platforms without sacrificing the ability to play your game.",
        breakdown: [
            {
                title: "",
                description: "",
            }
        ],
    },
    {
        name: "🧪 Quality Assurance",
        image: QualityAssurance,
        description: "Need a few extra hands to make sure everything is rock solid, or need to test the viability of a feature? We provide excellent quality assurance services, detailed bug reports, analysis, screenshots and video.",
        breakdown: [
            {
                title: "",
                description: "",
            }
        ],
    },
]

export const servicesGallerySizing = [
    {
        rows: 2,
        cols: 2,
    },
    {
    },
    {
    },
    {
        cols: 2,
    },
    {
        cols: 2,
    },
    {
        rows: 2,
        cols: 2,
    },
    {
    },
    {
    },
    {
        rows: 2,
        cols: 2,
    },
    // {
    // },
    // {
    // },
    // {
    //     cols: 2,
    // },
]