import { AccountCircle } from "@mui/icons-material";
import { FormControl, InputAdornment, TextField } from "@mui/material";
import { FC, ReactElement, useState } from "react";
let validator = require('validator')

export const NameInput: FC<any> = ({value, setValue}): ReactElement => {

    const [currentValue, setCurrentValue] = useState<string>("")

    const [isValid, setIsValid] = useState<boolean>(false)

    return(
        <FormControl>

            <TextField
                id="contact-name"
                label="name"
                InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <AccountCircle color="secondary" />
                    </InputAdornment>
                ),
                }}
                onChange={(e: any) => {
                    
                    setValue(e)
                    
                    if ( e.target.value !== "" ) {

                        console.log("name target value: ", e.target.value)

                        setIsValid(validator.isAlpha(e.target.value, ['en-US'], {ignore: ' '}))

                        setCurrentValue(e.target.value)

                    }

                }}
                placeholder={value}
                required
                sx={{
                    marginBottom: "10px",
                    width: "300px",
                }}
                error={ 
                    currentValue !== "" &&
                    (
                        !isValid ||
                        currentValue.trim().length < 3
                    )
                }
                helperText={
                    currentValue !== "" &&
                    (
                        !isValid ||
                        currentValue.trim().length < 3
                    )
                    ? "letters only and min length of 3"
                    : false
                }
                inputProps={{ maxLength: 30 }}
            />

        </FormControl>
    )
}