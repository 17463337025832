import { Box, Button, Typography, Link, } from "@mui/material";
import { FC, ReactElement } from "react";
import { NavLink } from "react-router-dom";
import { CurvedLine } from "../dividers/CurvedLine";
import { CurvedSegment } from "../dividers/CurvedSegment";
import { ServicesGallery } from "../services/ServicesGallery";

export const HomeServices: FC<any> = (): ReactElement => {

    return(
        <Box
        >

            <CurvedSegment
                direction="up"
                color="dark"
                marTop="0px"
                marBottom="-6px"
                snapTo="top"
            />

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingBottom: "3%",
                    backgroundColor: "primary.contrastText",
                }}
            >

            <CurvedLine
                color="white"
                direction="up"
            />

            <Typography
                fontSize="2rem"
                color="primary.light"
            >
                Services
            </Typography>

            <Typography
                textAlign="center"
                fontSize=".9rem"
                color="primary.main"
                maxWidth="600px"
                marginBottom="10px"
            >
                We offer a varaiety of services to help you along at any point in the Game Development Cycle. No matter the task, we can help get your game shipped and on the market.
            </Typography>

            <ServicesGallery />

            {/* <Link
                to={'services'}
                underline="none"
                key="services"
                component={NavLink}
            >

                <Button
                    sx={{
                        transition: "all 1s",
                        backgroundColor: "secondary.light",
                        marginTop: "10px",
                        ":hover": {
                            backgroundColor: "secondary.main",
                            color: "primary.light",
                            transform: "scale(1.1)"
                        },
                        color: "primary.dark",
                        paddingX: "20px",
                        letterSpacing: "1px",
                    }}
                >
                    full list of services
                </Button>

            </Link> */}
            
            </Box>

        </Box>
    )

}