import { FormControl, TextField } from "@mui/material";
import { FC, ReactElement, useState } from "react";

export const MessageInput: FC<any> = ({ value, setValue }): ReactElement => {

    const [currentValue, setCurrentValue] = useState<string>("")

    return(
        <FormControl>

            <TextField
                id="contact-message"
                label="message"
                multiline
                rows={4}
                placeholder={value}
                onChange={(e: any) => {

                    setValue(e)

                    if ( e.target.value !== "" ) {

                        setCurrentValue(e.target.value)

                    }
                }}
                required
                sx={{
                    width: "300px",
                }}
                error={currentValue !== "" && currentValue.length < 20}
                helperText={
                    currentValue !== "" && 
                    currentValue.length < 20
                    ? "message length must be between 20 and 330"
                    : false
                }
                inputProps={{ maxLength: 330 }}
            />

        </FormControl>
    )
    
}