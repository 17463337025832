import { FC, ReactElement } from "react"
import CurvedWhite from "../../assets/svg/curved-white.svg"
import CurvedDark from "../../assets/svg/curved-dark.svg"
import CurvedWhiteLeft from "../../assets/svg/curved-white-left.svg"
import CurvedDarkLeft from "../../assets/svg/curved-dark-left.svg"
import { Box } from "@mui/system"

export const CurvedSegment: FC<any> = ({
    direction,
    color,
    zInd,
    snapTo
}): ReactElement => {
    
    const baseImgStyling = {
        position: zInd ? "absolute" : "inherit",
        zIndex: zInd,
        // marginTop: marTop,
        // marginBottom: marBottom,
        width: "115%"
    }

    const imgSxStyling: any = 
        direction === "up"
        ?
        // up and top
        snapTo === "top"
        ?
        {
            ...baseImgStyling,
            top: "0px",
            marginBottom: "-1px"
        }
        :
        // up and bottom
        {
            ...baseImgStyling,
            bottom: "0px",
            marginTop: "-1px",
        }
        :
        // left
        direction === "left"
        ?
        {
            ...baseImgStyling,
            // transform: "rotate(-90deg)",
            // marginLeft: "-20px",
            // backgroundColor: "red",
        }
        :
        // right
        direction === "right"
        ?
        {
            ...baseImgStyling,
            transform: "rotate(180deg)"
        }
        :
        // down and top
        snapTo === "top"
        ?
        {
            ...baseImgStyling,
            transform: "rotate(180deg)",
            top: "0px",
            marginTop: "-1px",
        }
        :
        // down and bottom
        {
            ...baseImgStyling,
            transform: "rotate(180deg)",
            bottom: "0px",
            marginTop: "-1px"
        }

    const srcImg =
        color === "white"
        ? direction === "left" || direction === "right"
        ? CurvedWhiteLeft
        : CurvedWhite
        : direction === "left" || direction === "right"
        ? CurvedDarkLeft
        : CurvedDark

    return(
         <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: zInd,
           }}
        >

            <img
                style={imgSxStyling}
                src={srcImg}
                alt={`${color} curved segment in ${direction} position`}
            />

        </Box>
        
    )
    
}